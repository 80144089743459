import { types } from 'generic/core/ux/actions';
import _ from 'lodash';

const uxReducer = (
  state = {
    leftDrawerOpened: false,
    rightDrawerOpened: false,
    backPageLocation: null,
    resultsCompleteVisibleInResults: false,
    isRAGOpened: false,
    checkedSentences: [],
  },
  action,
) => {
  switch (action.type) {
    case types.OPEN_LEFT_DRAWER: {
      return {
        ...state,
        leftDrawerOpened: true,
      };
    }
    case types.CLOSE_LEFT_DRAWER: {
      return {
        ...state,
        leftDrawerOpened: false,
      };
    }
    case types.OPEN_RIGHT_DRAWER: {
      return {
        ...state,
        rightDrawerOpened: true,
      };
    }
    case types.TOGGLE_IS_RAG_INPUT_OPENED: {
      return {
        ...state,
        isRAGInputOpened: action.isOpened,
      };
    }
    case types.TOGGLE_TRANSCRIPTION_SENTENCE_CHECKBOX: {
      let newCheckedSentences = [...state.checkedSentences];
      if (newCheckedSentences.includes(action.sentenceStart)) {
        newCheckedSentences = _.without(newCheckedSentences, action.sentenceStart);
      } else {
        newCheckedSentences.push(action.sentenceStart);
      }
      return {
        ...state,
        checkedSentences: newCheckedSentences,
      };
    }
    case types.CLEAR_CHECKED_SENTENCES: {
      return {
        ...state,
        checkedSentences: [],
      };
    }
    case types.CLOSE_RIGHT_DRAWER: {
      return {
        ...state,
        rightDrawerOpened: false,
      };
    }
    case types.SET_BACK_PAGE_LOCATION: {
      return {
        ...state,
        backPageLocation: action.location,
      };
    }
    case types.UNSET_BACK_PAGE_LOCATION: {
      return {
        ...state,
        backPageLocation: null,
      };
    }
    case types.TOGGLE_RESULTS_COMPLETE_VISIBILITY_IN_RESULTS: {
      return {
        ...state,
        resultsCompleteVisibleInResults: action.isVisible,
      };
    }
    default:
      return state;
  }
};

export default uxReducer;
