import { useSelector } from 'react-redux';

import _ from 'lodash';
import { t } from 'i18next';
import {
  createHtmlAnnotationTag,
  generateLinkToResource,
  getDocumentBaseId,
  getValueByBaseFieldsConfig,
  getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';
import { format } from 'generic/utils/dateUtils';
import { useComputedTranscription } from 'generic/core/hooks/useComputedTranscription';

export default (document) => {
  const { qesdocument } = document;

  const docBaseId = getDocumentBaseId(document);
  const bases = useSelector((state) => state.config.bases);
  const resultsCompleteVisibleInResults = useSelector((state) => state.ux.resultsCompleteVisibleInResults);
  const documentBase = _.find(bases, { base: docBaseId });

  // noms des champs issues de la configuration de la base, permet d'être un peu dynamique
  // et avec de la chance, ne rien avoir à surcharger
  const id = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champId');
  const statusXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champEtat');
  const pdf = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champPdf');
  const titleXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champTitre');

  // noms des champs en durs, difficile de les rendre dynamique en l'état de l'API
  // on prend le premier élément du tableau de valeur s'il y en a plusieur.
  let textOriginal = getValueOrFirstValueFromArray(qesdocument?.Text_original);
  const text = getValueOrFirstValueFromArray(qesdocument?.Text);
  let textTranslated = '';
  if (textOriginal) {
    textTranslated = text;
  } else {
    textOriginal = text;
  }
  let lang = getValueOrFirstValueFromArray(qesdocument?.Lang);
  if (lang === 'None') {
    lang = '';
  }
  const datePubXml = getValueOrFirstValueFromArray(qesdocument?.Date_publication);
  const fileSize = getValueOrFirstValueFromArray(qesdocument?.File_size);
  const textJson = getValueOrFirstValueFromArray(qesdocument?.Text_json);
  const transcription = useComputedTranscription(textJson, textTranslated);

  const attachedFile = getValueOrFirstValueFromArray(qesdocument?.File)
    || getValueOrFirstValueFromArray(qesdocument['File.verbatim']);

  let htmlWithAnnotations = getValueOrFirstValueFromArray(qesdocument?.QES_Html);
  if (htmlWithAnnotations) {
    // On traite le texte contenant les annotations sous formes de balises, exemple :
    // <Coldconcept>Bla bla bla</Coldconcept> <Concept>bli bla blu</Concept>
    // <Sentiment>aime <Coldconcept>le chocolat</Coldconcept></SentimentUncategorized>
    htmlWithAnnotations = htmlWithAnnotations.replaceAll('\n', '\n\n');
    const tagsToRemove = [
      'Relation',
      'Sentiment',
      'SentimentUncategorized',
      'Unknown',
      'Unlocalized',
      'Ambiguous',
    ];
    // On vire tous les couple de tags qu'on ne veut pas garder
    tagsToRemove.forEach((tagToRemove) => {
      htmlWithAnnotations = htmlWithAnnotations.replaceAll(new RegExp(`</?${tagToRemove}>`, 'g'), '');
    });
    // On remplace chaque "balise" ouvrante par une balise span du style :
    // <span class="html-tag Coldconcept" type-text="concept froid">
    // (a) savoir que le style et l'affichage des annotations est géré dans le CSS du thème)
    htmlWithAnnotations = htmlWithAnnotations.replaceAll(
      /<([a-zA-Z]+)>/g,
      (tag, captured) => createHtmlAnnotationTag(captured),
    );
    // On remplace chaque "balise" fermante par une balise fermante </span>
    htmlWithAnnotations = htmlWithAnnotations.replaceAll(
      /<\/[a-zA-Z]+>/g,
      '</span>',
    );
  }
  //  dernières retouches avant le retour du résulat
  let attachedFileURL;
  if (attachedFile) {
    attachedFileURL = generateLinkToResource(docBaseId, attachedFile);
  }

  const frameUrls = qesdocument.Frame;
  const frameTimes = qesdocument.Frame_time;

  let frames = [];
  if (_.isArray(frameUrls)) {
    frames = frameUrls.map((frameUrl, index) => (
      {
        image: generateLinkToResource(docBaseId, frameUrl),
        time: frameTimes[index],
      }
    ));
  }

  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm', 'mpeg'];
  const audioExtensions = ['m4a', 'wav', 'mp3'];
  const imgExtensions = ['png', 'jpg', 'jpeg'];
  const isVideo = !_.isEmpty(attachedFile) && videoExtensions.includes(attachedFile.split('.').pop().toLowerCase());
  const isAudio = !_.isEmpty(attachedFile) && audioExtensions.includes(attachedFile.split('.').pop().toLowerCase());
  const isImage = !_.isEmpty(attachedFile) && imgExtensions.includes(attachedFile.split('.').pop().toLowerCase());
  const isPdf = !_.isEmpty(attachedFile) && attachedFile.toLowerCase().endsWith('.pdf');

  const title = titleXml || attachedFile || `[${t('document.title_undefined')}]`;
  const date = datePubXml ? format(datePubXml) : null;
  const status = +statusXml;
  const snippet = _.truncate(textOriginal, {
    length: resultsCompleteVisibleInResults ? 150 : 500,
    separator: /,? +/,
  });

  const sentences = qesdocument?.Phrase;

  return {
    attachedFile,
    attachedFileURL,
    date,
    frames,
    htmlWithAnnotations,
    id,
    lang,
    fileSize,
    pdf,
    snippet,
    status,
    textOriginal,
    textTranslated,
    title,
    transcription,
    isAudio,
    isImage,
    isVideo,
    isPdf,
    sentences,
  };
};
