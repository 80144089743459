import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { cleanupResults } from 'generic/core/search/actions';
import { cleanupDashboard } from 'generic/core/dashboard/actions';

import DashboardHeaderContainer from 'generic/containers/DashboardHeaderContainer';
import QuickResultsContainer from 'generic/containers/QuickResultsContainer';
import DashboardChartsContainer from 'generic/containers/DashboardChartsContainer';
import SaveDashboardDialogContainer from 'generic/containers/SaveDashboardDialogContainer';

const DashboardWrapper = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => (
    // Cleanup des widgets lorsque le composant est "unmount" (donc quand on
    // change de page)
    () => {
      if ((!history?.location?.pathname?.startsWith('/ged')
        && !history?.location?.pathname?.startsWith('/search/results'))
        || history.action === 'POP') {
        // Si jamais on sort de l'univers "resultats", on vire
        // les résultats et la stratégie également, pour que le refresh
        // suivant du dashboard soit propre.
        dispatch(cleanupResults());
      }
      dispatch(cleanupDashboard());
    }
  ), [dispatch, history]);

  return (
    <Box
      className="desktopOnlyOverflow"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'inherit',
        backgroundColor: 'background.special',
      }}
    >
      <QuickResultsContainer />
      <DashboardHeaderContainer />
      <DashboardChartsContainer />
      <SaveDashboardDialogContainer />
    </Box>
  );
};

export default DashboardWrapper;
