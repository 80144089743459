import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Typography,
  Box,
  Button,
  Stack,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

const CustomTextField = ({ innerRef = null, ...rest }) => (
  <TextField
    sx={(theme) => ({
      borderRadius: '4px',
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : 'unset',
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#ced4da' : 'rgba(255, 255, 255, 0.23)',
      fontWeight: 'bold',
      width: '100%',

      '& > .MuiFormHelperText-root': {
        backgroundColor: theme.palette.primary.transparent,
        padding: '.25em 0 .25em .75em',
      },
    })}
    InputProps={{
      disableUnderline: true,
      inputProps: {
        ref: innerRef,
        sx: {
          height: '2em',
          padding: '.25em 0 .25em .75em',
        },
      },
    }}
    variant="standard"
    margin="normal"
    size="medium"
    {...rest}
  />
);
CustomTextField.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

const LoadingButtonWithIndicator = ({ ...props }) => (
  <LoadingButton
    sx={{ minWidth: '180px' }}
    color="secondary"
    loadingIndicator={<CircularProgress color="primary" size={16} />}
    {...props}
  />
);

const FormLogin = ({
  variant = 'logon', loading, logonError, resetPasswordLoading, resetPasswordError, toggleResetPassword,
}) => {
  const { t } = useTranslation();

  const usernameInput = useRef(null);
  const usernameOrEmailInput = useRef(null);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      sx={{ minHeight: '30vh' }}
    >
      <Fragment>
        <Grid
          item
          container
          direction="column"
          sx={{ alignItems: 'center' }}
        >
          {
            variant === 'reset'
              ? (
                <Typography sx={{ fontSize: '1.5rem' }}>
                  {t('logon.forgotten_password_label')}
                </Typography>
              ) : (
                <Fragment>
                  <Typography variant="h3">
                    {t('logon.welcome')}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="center"
                  >
                    {t('logon.log_in')}
                  </Typography>
                </Fragment>
              )
          }
        </Grid>

        <Grid
          item
          container
          direction="column"
          sx={{
            justifyContent: 'center',
            width: '80%',
          }}
        >
          {variant === 'reset'
            ? (
              <Fragment>
                <Box sx={{ mb: 1 }}>
                  <Field
                    autoFocus
                    component={CustomTextField}
                    name="emailOrLogin"
                    placeholder={t('logon.login_or_email')}
                    innerRef={usernameOrEmailInput}
                    required
                    type="text"
                  />
                </Box>

                {resetPasswordError && (
                  <Typography
                    color="error"
                    variant="subtitle2"
                  >
                    {t('logon.forgotten_password_error')}
                  </Typography>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Box sx={{ mb: 1 }}>
                  <Field
                    autoComplete="username"
                    autoFocus
                    component={CustomTextField}
                    name="login"
                    placeholder={t('logon.login')}
                    innerRef={usernameInput}
                    required
                    type="text"
                  />
                </Box>

                <Box sx={{ mb: 1 }}>
                  <Field
                    autoComplete="current-password"
                    component={CustomTextField}
                    name="password"
                    placeholder={t('logon.password')}
                    required
                    type="password"
                  />
                </Box>

                {logonError && (
                  <Typography
                    color="error"
                    variant="subtitle2"
                  >
                    {t('logon.fail_auth')}
                  </Typography>
                )}
              </Fragment>
            )}
        </Grid>

        <Stack
          direction="column"
          spacing={1}
          sx={{ justifyContent: 'center' }}
        >
          {variant === 'reset'
            ? (
              <Fragment>
                <LoadingButtonWithIndicator
                  disabled={resetPasswordLoading}
                  loading={resetPasswordLoading}
                  type="submit"
                >
                  {t('form.send')}
                </LoadingButtonWithIndicator>

                <Button
                  size="small"
                  variant="text"
                  onClick={() => {
                    toggleResetPassword();
                    usernameOrEmailInput.current.focus();
                  }}
                  sx={{ textTransform: 'none', color: 'backgroundLogin.contrastText' }}
                >
                  {t('logon.forgotten_password_back')}
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <LoadingButtonWithIndicator
                  disabled={loading}
                  loading={loading}
                  type="submit"
                >
                  {t('logon.to_login')}
                </LoadingButtonWithIndicator>

                <Button
                  size="small"
                  variant="text"
                  onClick={() => {
                    toggleResetPassword();
                    usernameInput.current.focus();
                  }}
                  sx={{ textTransform: 'none', color: 'backgroundLogin.contrastText' }}
                >
                  {t('logon.forgotten_password')}
                </Button>
              </Fragment>
            )}
        </Stack>
      </Fragment>
    </Grid>
  );
};

FormLogin.propTypes = {
  loading: PropTypes.bool.isRequired,
  logonError: PropTypes.bool.isRequired,
  resetPasswordLoading: PropTypes.bool.isRequired,
  resetPasswordError: PropTypes.bool.isRequired,
  toggleResetPassword: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['logon', 'reset']),
};

export default FormLogin;
