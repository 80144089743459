import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton, useTheme } from '@mui/material';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm';
import _ from 'lodash';

const waveRegions = RegionsPlugin.create();

const Waveform = ({ mediaRef = null, regions = [] }) => {
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const theme = useTheme();
  const [isReady, setIsReady] = useState(false);
  const [regionsLocal, setRegionsLocal] = useState(null);

  useEffect(() => {
    waveSurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      cursorColor: '#333',
      barWidth: 2,
      height: 80,
      responsive: true,
      media: mediaRef.current,
      normalize: true,
      plugins: [waveRegions],
      fetchParams: {
        credentials: 'include',
      },
    });

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
      }
    };
  }, [mediaRef]);

  useEffect(() => {
    waveSurferRef.current.on('decode', () => {
      setIsReady(true);
    });
  }, []);

  useEffect(() => {
    if (isReady && (!_.isEqual(regions, regionsLocal))) {
      waveRegions.clearRegions();
      _.each(regions, (region) => {
        waveRegions.addRegion({
          start: region.start,
          end: region.end,
          color: region.color,
          drag: false,
          resize: false,
        });
      });
      setRegionsLocal(regions);
    }
  }, [isReady, regions, regionsLocal]);

  useEffect(() => {
    waveSurferRef.current.setOptions({
      waveColor: theme.palette.mode === 'light' ? '#aaaaaa' : '#555555',
      progressColor: theme.palette.mode === 'light' ? '#000000' : '#ffffff',
    });
  }, [theme.palette.mode]);

  return (
    <Fragment>
      {!isReady && (
        <Skeleton width="100%" height={80} animation="wave" variant="rectangular" />
      )}
      <Box ref={waveformRef} sx={{ display: isReady ? 'block' : 'none' }} />
    </Fragment>
  );
};

Waveform.propTypes = {
  mediaRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
};

export default Waveform;
