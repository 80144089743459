import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { closeDialogUploadFiles } from 'generic/core/actions/actions';
import { uploadFileToCreateDocument } from 'generic/core/files/actions';
import UploadFilesDialog from 'generic/components/dialogs/UploadFilesDialog';
import useAuthorizedExtensions from 'generic/core/hooks/useAuthorizedExtensions';
import QES_CONSTANTS from 'generic/core/qes/constants';

const {
  UPLOAD_FILE_SETTINGS,
  FILE_UPLOAD_FORMATS,
} = QES_CONSTANTS;

const UploadFilesDialogContainer = ({
  baseId,
  baseMisePageId = undefined,
}) => {
  const dispatch = useDispatch();
  const collections = useSelector((state) => state.collections.collections);
  const filesToUpload = useSelector((state) => state.files.filesToUpload);
  const idCorpus = useSelector((state) => state.actions.idCorpus);
  const idUser = useSelector((state) => state.config.user.utilisateur);
  const open = useSelector((state) => state.actions.dialogUploadFilesOpened);
  const withCollections = useSelector((state) => state.config.settings?.withCollections);
  const filesContents = useRef({});

  const userCorpora = useMemo(() => {
    if (withCollections) {
      return _.sortBy(
        _.filter(_.flatMap(collections, 'corpora'), { utilisateur: idUser }),
        (corpus) => corpus.libelle.toLowerCase(),
      );
    }
    return [];
  }, [collections, idUser, withCollections]);

  const [idCorpusSelect, setIdCorpusSelect] = useState(userCorpora[0]?.collection);

  useEffect(() => {
    if (idCorpus) {
      setIdCorpusSelect(idCorpus);
    } else if (!_.isEmpty(userCorpora)) {
      setIdCorpusSelect(userCorpora[0].collection);
    }
  }, [idCorpus, userCorpora]);

  const handleSelectCorpus = (event) => {
    setIdCorpusSelect(event.target.value);
  };

  const type = UPLOAD_FILE_SETTINGS?.UPLOAD_FILE_TYPE || FILE_UPLOAD_FORMATS.technicalDeposit;
  const authorizedExtensions = useAuthorizedExtensions('dropzone').getFileUpload(type, baseId, baseMisePageId);

  const uploadParams = {
    base: baseId,
    type,
    corpusId: idCorpusSelect,
  };

  if (baseMisePageId) {
    uploadParams.base_mise_page = baseMisePageId;
  }

  const handleClose = () => {
    dispatch(closeDialogUploadFiles());
    filesContents.current = {};
  };

  const handleUploadClick = () => {
    if (_.isEmpty(filesToUpload)) {
      return;
    }
    _.forEach(filesToUpload, (value) => {
      if (!value.success) {
        const fd = new FormData();
        fd.append('file', filesContents.current[value.path]);
        dispatch(uploadFileToCreateDocument(uploadParams, fd, value.name));
      }
    });
  };

  if (!open) {
    return null;
  }
  return (
    <UploadFilesDialog
      filesContents={filesContents}
      corpora={userCorpora}
      idCorpusSelect={idCorpusSelect}
      filesToUpload={filesToUpload}
      handleClose={handleClose}
      handleSelectCorpus={handleSelectCorpus}
      handleUploadClick={handleUploadClick}
      accept={authorizedExtensions}
      withCollections={withCollections}
      noChoiceSelection={_.isNumber(idCorpus)}
    />
  );
};

UploadFilesDialogContainer.propTypes = {
  baseId: PropTypes.number.isRequired,
  baseMisePageId: PropTypes.number,
};

export default UploadFilesDialogContainer;
