import {
  put,
  call,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import { snackActions } from 'generic/utils/snackbar';
import { CONSTANTS } from 'generic/core/constants';

import {
  activateCollectionError,
  activateCollectionSuccess,
  closeCollectionDialog,
  createCollectionError,
  createCollectionSuccess,
  deleteCollectionsError,
  deleteCollectionSuccess,
  editCollectionError,
  editCollectionSuccess,
  fetchCollectionsError,
  fetchCollectionsSuccess,
  types,
} from 'generic/core/collections/actions';

import {
  activateCollection,
  createCollection,
  deleteCollections,
  editCollection,
  fetchCollections as fetchCollectionsApi,
} from 'generic/api/collection';

const { MAIN_PAGE } = CONSTANTS;

/* Saga des collections */
function* workFetchCollections() {
  try {
    const collections = yield call(fetchCollectionsApi);
    yield put(fetchCollectionsSuccess(collections));
  } catch (error) {
    yield put(fetchCollectionsError(error));
    console.error(error);
    snackActions.error(i18next.t('collections.error_fetching'));
  }
}

function* watchFetchCollections() {
  yield takeLatest(types.FETCH_COLLECTIONS, workFetchCollections);
}

function* workActivateCollection({ id }) {
  try {
    const activeCollection = yield call(activateCollection, id);
    yield put(activateCollectionSuccess(activeCollection));
    const pathname = yield select((state) => state.router.location.pathname);
    if (pathname.startsWith('/collections')) {
      yield put(push(MAIN_PAGE));
    }
  } catch (error) {
    yield put(activateCollectionError(error));
    console.error(error);
    snackActions.error(i18next.t('collections.corpus.error_activated'));
  }
}

function* watchActivateCollection() {
  yield takeLatest(types.ACTIVATE_COLLECTION, workActivateCollection);
}

function* workCreateCollection({ typeRessource, formValues, shouldOpenNextStep }) {
  try {
    const params = {
      collaborators: formValues.collaborators,
      collection_parente: formValues.collection_parente,
      description: formValues.description,
      image: formValues.image,
      libelle: formValues.libelle,
      visibilite_collection: formValues.visibilite_collection,
    };
    const results = yield call(createCollection, { bodyItems: { ...params } });
    yield put(createCollectionSuccess(results, shouldOpenNextStep));
    yield put(closeCollectionDialog());
    snackActions.success(i18next.t(`collections.${typeRessource}.created`));
  } catch (error) {
    yield put(createCollectionError(error));
    console.error(error);
    snackActions.error(i18next.t(`collections.${typeRessource}.error_creating`));
  }
}

function* watchCreateCollection() {
  yield takeLatest(types.CREATE_COLLECTION, workCreateCollection);
}

function* workEditCollection({ typeRessource, formValues }) {
  try {
    const params = {
      collaborators: formValues.collaborators,
      collection: formValues.id,
      collection_parente: formValues.collection_parente,
      description: formValues.description,
      image: formValues.image,
      libelle: formValues.libelle,
      visibilite_collection: formValues.visibilite_collection,
    };
    const results = yield call(editCollection, { bodyItems: { ...params } });
    yield put(editCollectionSuccess(results));
    yield put(closeCollectionDialog());
    snackActions.success(i18next.t(`collections.${typeRessource}.edited`));
  } catch (error) {
    yield put(editCollectionError(error));
    console.error(error);
    snackActions.error(i18next.t(`collections.${typeRessource}.error_editing`));
  }
}

function* watchEditCollection() {
  yield takeLatest(types.EDIT_COLLECTION, workEditCollection);
}

function* workDeleteCollections({ typeRessource, ids }) {
  try {
    const results = yield call(deleteCollections, ids);
    yield put(deleteCollectionSuccess(results));
    snackActions.success(i18next.t(`collections.${typeRessource}.deleted`, { count: ids.length }));
  } catch (error) {
    yield put(deleteCollectionsError(error));
    console.error(error);
    snackActions.error(i18next.t(`collections.${typeRessource}.error_deleting`, { count: ids.length }));
  }
}

function* watchDeleteCollections() {
  yield takeLatest(types.DELETE_COLLECTIONS, workDeleteCollections);
}

export default {
  watchActivateCollection,
  watchCreateCollection,
  watchDeleteCollections,
  watchEditCollection,
  watchFetchCollections,
};
