import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Add,
  Article,
  Audiotrack,
  Check,
  Delete,
  ErrorOutline,
  Image,
  Mail,
  Videocam,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';

import { useDropzone } from 'react-dropzone';
import { prettyPrintSize } from 'generic/utils/mathUtils';

const IconFile = ({
  fileName,
  filesToUpload,
}) => {
  let iconFile;
  const fileToUpload = _.find(filesToUpload, { name: fileName });
  if (fileToUpload && fileToUpload.loading) {
    iconFile = <CircularProgress size={15} sx={{ m: '5px' }} />;
  } else if (fileToUpload && fileToUpload.success) {
    iconFile = <Check fontSize="small" sx={{ m: '5px' }} />;
  } else if (fileToUpload
    && !fileToUpload.success
    && fileToUpload.success !== undefined) {
    iconFile = <ErrorOutline fontSize="small" sx={{ p: 'mpx' }} />;
  } else {
    iconFile = (
      <Box
        variant="span"
        sx={{
          display: 'inline-block',
          marginRight: '30px',
        }}
      />
    );
  }

  return iconFile;
};

const IconPreview = ({ file, handleOpenPreviewFile }) => {
  let iconPreview;
  let disabledIcon = true;

  if (
    _.includes(file.type, 'image')
    || _.includes(file.type, 'video')
    || _.includes(file.type, 'audio')
    || _.includes(file.type, 'pdf')
  ) {
    iconPreview = <Visibility fontSize="small" color={file.openPreview ? 'primary' : 'inherit'} />;
    disabledIcon = false;
  } else {
    iconPreview = <VisibilityOff fontSize="small" />;
  }

  return (
    <IconButton
      size="small"
      onClick={() => handleOpenPreviewFile(file.name)}
      disabled={disabledIcon}
    >
      {iconPreview}
    </IconButton>
  );
};

IconPreview.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    openPreview: PropTypes.bool,
  }).isRequired,
  handleOpenPreviewFile: PropTypes.func.isRequired,
};

const IconTypeFile = ({ fileType }) => {
  let iconTypeFile;
  if (_.includes(fileType, 'image')) {
    iconTypeFile = <Image fontSize="small" color="primary" />;
  } else if (_.includes(fileType, 'video')) {
    iconTypeFile = <Videocam fontSize="small" color="primary" />;
  } else if (_.includes(fileType, 'audio')) {
    iconTypeFile = <Audiotrack fontSize="small" color="primary" />;
  } else if (_.includes(fileType, 'message')) {
    iconTypeFile = <Mail fontSize="small" color="primary" />;
  } else {
    iconTypeFile = <Article fontSize="small" color="primary" />;
  }

  return iconTypeFile;
};

const UploadFiles = ({
  handleFileChange,
  handleRemoveFile,
  handleOpenPreviewFile,
  extensionsQTA,
  filesToUpload = [],
  accept = '',
}) => {
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    if (handleFileChange) {
      handleFileChange(acceptedFiles);
    }
  }, [handleFileChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '1',
        gap: '16px 16px',
        flexDirection: 'column',
      }}
    >
      <TableContainer component={Paper} sx={{ maxHeight: '220px' }}>
        <Table size="small">
          <TableBody>
            {filesToUpload.map((file) => (
              <TableRow
                key={file.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <IconTypeFile fileType={file.type} />
                    {file.name}
                  </Box>
                </TableCell>
                <TableCell align="right">{prettyPrintSize(+file.size)}</TableCell>
                <TableCell align="right">
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <IconPreview file={file} handleOpenPreviewFile={handleOpenPreviewFile} />
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveFile(file)}
                      disabled={file.success}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                    <IconFile fileName={file.name} filesToUpload={filesToUpload} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        {...getRootProps()}
        sx={{
          border: '1px solid',
          borderColor: isDragActive ? 'primary.main' : 'grey.500',
          borderRadius: 2,
          padding: 2,
          height: _.isEmpty(filesToUpload) ? 200 : 'initial',
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: isDragActive ? 'action.hover' : 'background.default',
          cursor: 'pointer',
          color: isDragActive ? 'primary.main' : 'text.primary',
        }}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: _.isEmpty(filesToUpload) ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <Add />
          <Typography variant="h6">
            {t('form.drag_and_drop')}
          </Typography>
          {_.isEmpty(filesToUpload) && (
            <Typography variant="body2">
              {_.join(_.take(_.map(extensionsQTA, _.toUpper), 4), ', ') }
              {extensionsQTA.length > 4 && (<span>...</span>)}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

UploadFiles.propTypes = {
  handleFileChange: PropTypes.func.isRequired,
  handleOpenPreviewFile: PropTypes.func.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  extensionsQTA: PropTypes.arrayOf(PropTypes.string).isRequired,
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
  accept: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
  ]),
  multiple: PropTypes.bool,
};

export default UploadFiles;
