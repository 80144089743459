import React from 'react';
import { Box, getContrastRatio, useTheme } from '@mui/material';
import { CONSTANTS } from 'generic/core/constants';
import FormLoginContainer from 'generic/containers/auth/FormLoginContainer';
import FormResetPasswordConfrimationContainer from 'generic/containers/auth/FormResetPasswordConfrimationContainer';

import { overrideRessource } from 'generic/utils/utils';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const { PROJECT_NAME } = CONSTANTS;

const logoWhite = overrideRessource('img/login-logo.png', true);
const logoBlack = overrideRessource('img/login-logo-black.png', true);

const AuthenticationLanding = () => {
  const { path } = useRouteMatch();
  const theme = useTheme();

  const backgroundLogin = theme.palette.backgroundLogin.main;
  const activeLogo = getContrastRatio(backgroundLogin, '#fff') > 3 ? logoWhite : logoBlack;

  return (
    <Box
      sx={{
        color: 'backgroundLogin.contrastText',
        bgcolor: 'backgroundLogin.main',
        height: '100vh',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
        flexGrow: '1',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          color: 'backgroundLogin.light',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: 'auto', md: '50%' },
          borderRight: { xs: 'none', md: '2px solid' },
        }}
      >
        <Box
          component="img"
          src={activeLogo}
          alt={PROJECT_NAME}
          sx={{
            marginBottom: { xs: '2em', md: '0' },
            ...theme?.custom?.login?.logo || '',
          }}
        />
      </Box>
      <Box
        sx={{
          padding: '0 10%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: 'auto', md: '50%' },
        }}
      >
        <Switch>

          <Route path={`${path}/reset-password-confirmation`}>
            <FormResetPasswordConfrimationContainer />
          </Route>

          <Route>
            <FormLoginContainer />
          </Route>
        </Switch>

      </Box>
    </Box>
  );
};

export default AuthenticationLanding;
