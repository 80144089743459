export const types = {
  ACTIVATE_COLLECTION: '@COLLECTIONS/ACTIVATE_COLLECTION',
  ACTIVATE_COLLECTION_SUCCESS: '@COLLECTIONS/ACTIVATE_COLLECTION_SUCCESS',
  ACTIVATE_COLLECTION_ERROR: '@COLLECTIONS/ACTIVATE_COLLECTION_ERROR',
  CLEANUP_COLLECTIONS: '@COLLECTIONS/CLEANUP_COLLECTIONS',
  CLEAR_UPLOAD_COLLECTION_IMAGE: '@COLLECTIONS/CLEAR_UPLOAD_COLLECTION_IMAGE',
  CLOSE_COLLECTION_DIALOG: '@COLLECTIONS/CLOSE_COLLECTION_DIALOG',
  CREATE_COLLECTION: '@COLLECTIONS/CREATE_COLLECTION',
  CREATE_COLLECTION_SUCCESS: '@COLLECTIONS/CREATE_COLLECTION_SUCCESS',
  CREATE_COLLECTION_ERROR: '@COLLECTIONS/CREATE_COLLECTION_ERROR',
  EDIT_COLLECTION: '@COLLECTIONS/EDIT_COLLECTION',
  EDIT_COLLECTION_SUCCESS: '@COLLECTIONS/EDIT_COLLECTION_SUCCESS',
  EDIT_COLLECTION_ERROR: '@COLLECTIONS/EDIT_COLLECTION_ERROR',
  DELETE_COLLECTIONS: '@COLLECTIONS/DELETE_COLLECTIONS',
  DELETE_COLLECTIONS_SUCCESS: '@COLLECTIONS/DELETE_COLLECTIONS_SUCCESS',
  DELETE_COLLECTIONS_ERROR: '@COLLECTIONS/DELETE_COLLECTIONS_ERROR',
  FETCH_COLLECTIONS: '@COLLECTIONS/FETCH_COLLECTIONS',
  FETCH_COLLECTIONS_SUCCESS: '@COLLECTIONS/FETCH_COLLECTIONS_SUCCESS',
  FETCH_COLLECTIONS_ERROR: '@COLLECTIONS/FETCH_COLLECTIONS_ERROR',
  OPEN_COLLECTION_DIALOG: '@COLLECTIONS/OPEN_COLLECTION_DIALOG',
  UPLOAD_COLLECTION_IMAGE: '@COLLECTIONS/UPLOAD_COLLECTION_IMAGE',
  UPLOAD_COLLECTION_IMAGE_SUCCESS: '@COLLECTIONS/UPLOAD_COLLECTION_IMAGE_SUCCESS',
  UPLOAD_COLLECTION_IMAGE_ERROR: '@COLLECTIONS/UPLOAD_COLLECTION_IMAGE_ERROR',
};

/* Actions des collections */
export const cleanupCollections = () => ({
  type: types.CLEANUP_COLLECTIONS,
});

export const fetchCollections = () => ({
  type: types.FETCH_COLLECTIONS,
});

export const fetchCollectionsSuccess = (collections) => ({
  type: types.FETCH_COLLECTIONS_SUCCESS,
  collections,
});

export const fetchCollectionsError = (response) => ({
  type: types.FETCH_COLLECTIONS_ERROR,
  response,
});

export const activateCollection = (id) => ({
  type: types.ACTIVATE_COLLECTION,
  id,
});

export const activateCollectionSuccess = (activeCollection) => ({
  type: types.ACTIVATE_COLLECTION_SUCCESS,
  activeCollection,
});

export const activateCollectionError = (response) => ({
  type: types.ACTIVATE_COLLECTION_ERROR,
  response,
});

export const openCollectionDialog = (typeRessource, mode, formValues) => ({
  type: types.OPEN_COLLECTION_DIALOG,
  typeRessource,
  mode,
  formValues,
});

export const closeCollectionDialog = () => ({
  type: types.CLOSE_COLLECTION_DIALOG,
});

export const clearUploadCollectionImage = () => ({
  type: types.CLEAR_UPLOAD_COLLECTION_IMAGE,
});

export const createCollection = (typeRessource, formValues, shouldOpenNextStep) => ({
  type: types.CREATE_COLLECTION,
  formValues,
  typeRessource,
  shouldOpenNextStep,
});

export const createCollectionSuccess = (results, shouldOpenNextStep) => ({
  type: types.CREATE_COLLECTION_SUCCESS,
  results,
  shouldOpenNextStep,
});

export const createCollectionError = (response) => ({
  type: types.CREATE_COLLECTION_ERROR,
  response,
});

export const editCollection = (typeRessource, formValues) => ({
  type: types.EDIT_COLLECTION,
  formValues,
  typeRessource,
});

export const editCollectionSuccess = (results) => ({
  type: types.EDIT_COLLECTION_SUCCESS,
  results,
});

export const editCollectionError = (response) => ({
  type: types.EDIT_COLLECTION_ERROR,
  response,
});

export const deleteCollections = (typeRessource, ids) => ({
  type: types.DELETE_COLLECTIONS,
  ids,
  typeRessource,
});

export const deleteCollectionSuccess = (results) => ({
  type: types.DELETE_COLLECTIONS_SUCCESS,
  results,
});

export const deleteCollectionsError = (response) => ({
  type: types.DELETE_COLLECTIONS_ERROR,
  response,
});
