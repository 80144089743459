import _ from 'lodash';
import loadable from '@loadable/component';
import { CUSTOM_PATH } from 'generic/core/constants';

export const getUserFromStorage = () => {
  const item = localStorage.getItem('user');
  return item ? JSON.parse(item) : {};
};

export const getLogonFromStorage = () => _.get(getUserFromStorage(), 'logon');

export const getTokenFromStorage = () => localStorage.getItem('token');

export const cleanStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('config');
};

// Permet de prendre un arbre avec nested children et
// de le mettre à plat dans un tableau à une dimension
export const treeFlattener = (tree, childrenKey = 'children') => {
  const recurse = (nodes) => (
    _.map(
      nodes,
      (node) => ([
        _.omit(node, childrenKey),
        recurse(node[childrenKey]),
      ]),
    )
  );

  return _.flattenDeep(recurse(tree, []));
};

export const stripHtmlTags = (dangerouslyHTML) => {
  const div = document.createElement('div');
  div.innerHTML = dangerouslyHTML;
  const text = div.textContent || div.innerText || '';

  return text;
};

export const splitAndCleanEmptyValues = function splitValuesFct(semiColonString) {
  return semiColonString.split(/\s*;+\s*/).filter((s) => s !== '');
};

export const testBeforeUnload = (event) => {
  // eslint-disable-next-line no-param-reassign
  (event || window.event).returnValue = null;
  return null;
};

/**
 * Permet de loader une ressource depuis le générique OU depuis un répertoire custom en se basant sur la variable
 * d'environnement `CUSTOM_PATH`
 * @param {String} path chemin relatif à partir de `generic/` ou `custom/client/`
 * @param {boolean} unlessComponent true s'il ne s'agit pas d'un composant React, false sinon
 * @returns {*} la ressource tel qu'un import ou un require le ferait
 */
export const overrideRessource = (path, unlessComponent = false) => {
  const isDev = (process.env.NODE_ENV === 'development');

  // Premier cas : on override un js, une image, etc.
  if (unlessComponent) {
    let staticElement;
    let hasCustom = false;
    // Si un CUSTOM_PATH existe
    if (CUSTOM_PATH) {
      /* eslint-disable */
      try {
        // On tente d'importer le fichier custom
        staticElement = require(`custom/${CUSTOM_PATH}/${path}`);
        if (isDev) {
          console.info(`CUSTOM PATH LOADED : custom/${CUSTOM_PATH}/${path}`);
        }
        hasCustom = true;
      } catch (err) {
        hasCustom = false;
      }
    }
    // Si aucun fichier custom n'existe, on renvoie le générique
    if (!hasCustom) {
      staticElement = require(`generic/${path}`);
    }
    /* eslint-enable */
    return staticElement;
  }
  // Second cas : on override un composant
  // Si un CUSTOM_PATH est vide
  if (!CUSTOM_PATH) {
    // On renvoie le générique directement
    return loadable(() => import(`generic/${path}`));
  }
  // Sinon, on tente d'import le custom, et si il n'est pas trouvé,
  // on renvoie le générique
  return loadable(
    () => import(`custom/${CUSTOM_PATH}/${path}`)
      .then((module) => {
        if (isDev) {
          console.info(`CUSTOM PATH LOADED : custom/${CUSTOM_PATH}/${path}`);
        }
        return module;
      })
      .catch(() => import(`generic/${path}`)),
  );
};

const DEFAULT_COLOR = 1000; // let the system default choose for use

export const getThemeAppLocal = () => {
  let themeAppLocal = Number(localStorage.getItem('theme_qes') || DEFAULT_COLOR); // 1 || 2 || 1000

  if (Number.isNaN(themeAppLocal)) {
    themeAppLocal = DEFAULT_COLOR;
  }
  return themeAppLocal;
};
