import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import { formatTime } from 'generic/utils/dateUtils';

const PatchSpeakerDialog = ({
  existingSpeakers = [],
  handleClose,
  handleSubmit,
  loading = false,
  sentenceStart = null,
  speakerId,
}) => {
  const { t } = useTranslation();
  const [newSpeakerId, setNewSpeakerId] = useState('');

  return (
    <Dialog
      scroll="paper"
      onKeyPress={(event) => {
        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
          handleSubmit(newSpeakerId);
        }
      }}
      open
      PaperProps={{
        sx: { minWidth: '700px' },
      }}
    >
      <DialogTitle>
        {t('ged.edit_speaker_dialog')}
      </DialogTitle>
      <DialogContent
        dividers
      >
        <Trans>
          {t('ged.edit_speaker_sure', {
            speakerId,
            forSentenceAt: sentenceStart !== null ? (
              ` ${t('ged.edit_speaker_sure_for_sentence_at')} ${formatTime(sentenceStart * 1000)}`
            ) : '',
          })}
        </Trans>
        <FormControl
          sx={{ my: 2, width: '100%' }}
        >
          <Autocomplete
            freeSolo
            multiple={false}
            value={newSpeakerId}
            options={existingSpeakers}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('ged.new_speaker_name')}
                onChange={(event) => setNewSpeakerId(event.target.value)}
              />
            )}
            getOptionLabel={(label) => label}
            onChange={(e, value) => setNewSpeakerId(value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
        >
          {t('ui.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          disabled={!_.trim(newSpeakerId)}
          onClick={() => handleSubmit(newSpeakerId)}
        >
          {t('form.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

PatchSpeakerDialog.propTypes = {
  existingSpeakers: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  sentenceStart: PropTypes.number,
  speakerId: PropTypes.string.isRequired,
};

export default PatchSpeakerDialog;
