import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { restoreStateFromStorage } from 'generic/core/auth/actions';
import { resetFormFields } from 'generic/core/config/actions';
import { clearSelection } from 'generic/core/selection/actions';
import Layout from 'generic/components/pages/Layout';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import { cleanupQuickResults, cleanupResults } from 'generic/core/search/actions';
import { fetchCollections } from 'generic/core/collections/actions';
import { CONSTANTS } from 'generic/core/constants';

const { MAIN_PAGE } = CONSTANTS;

const ProtectedRoutes = ({
  component: Component,
  exact = true,
  path,
  location = { state: { referrer: {} } },
  keepFormConfig = false,
  title = 'Infomedia',
  needsAnActiveCollection = false,
}) => {
  const hasActiveCollection = useSelector((state) => !_.isEmpty(_.get(state, 'collections.activeCollection', {})));
  const logged = useSelector((state) => state.auth.logged);
  const loading = useSelector((state) => state.auth.loading);
  const loadingCollections = useSelector((state) => state.collections.loadingCollections);
  const userForbiddenRoutes = useSelector((state) => state.config.user?.forbiddenRoutes);
  const userWebTrackingAccess = useSelector((state) => state.config.user?.accesAnR);
  const withCollections = useSelector((state) => state.config.settings?.withCollections);

  const dispatch = useDispatch();

  useEffect(() => {
    if (logged && !keepFormConfig) {
      // Ici on réinitialise le formulaire si on a pas la prop keepFormConfig à true
      dispatch(resetFormFields());
      // On vide également la sélection ET les résultats * poof *
      dispatch(clearSelection());
      dispatch(cleanupResults());
      dispatch(cleanupQuickResults());
      dispatch(clearSelection({ quickResultsScope: true }));
    }
  }, [path, dispatch, logged, keepFormConfig]);

  useEffect(() => {
    if (loading) {
      // Si jamais on arrive ici c'est que l'authentification est en
      // "attente", et qu'il faut tenter de voir si l'utilisateur est
      // connecté via le localStorage
      dispatch(restoreStateFromStorage());
    }
  }, [loading, dispatch]);

  useEffect(() => {
    if (withCollections && logged) {
      dispatch(fetchCollections());
    }
  }, [dispatch, logged, withCollections]);

  if (loading || (withCollections && loadingCollections)) {
    // Tant qu'on ne sait pas si l'utilisateur est réellement connecté ou non,
    // ou si l'application fonctionne avec des collections
    // et que le `fetchCollections` n'est pas terminé,
    // on affiche un preloader global
    return (
      <GlobalLoading />
    );
  }

  if (!logged) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referrer: location },
        }}
      />
    );
  }

  if ((!_.isEmpty(userForbiddenRoutes) && userForbiddenRoutes.includes(path))
    || (path.indexOf('/anr') === 0 && !userWebTrackingAccess)
    || (path === '/collections' && !withCollections)
  ) {
    return (
      <Redirect
        to={{
          pathname: MAIN_PAGE,
          state: { referrer: location },
        }}
      />
    );
  }

  if (
    withCollections
    && !hasActiveCollection
    && needsAnActiveCollection
  ) {
    return (
      <Redirect
        to={{
          pathname: '/collections',
          state: { referrer: location },
        }}
      />
    );
  }

  document.title = title;
  return (
    <Layout
      exact={exact}
      path={path}
      component={Component}
    />
  );
};

ProtectedRoutes.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  exact: PropTypes.bool,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  keepFormConfig: PropTypes.bool,
  needsAnActiveCollection: PropTypes.bool,
  title: PropTypes.string,
};

export default ProtectedRoutes;
