import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';
import { getRandomColor } from 'generic/utils/colorUtils';
import _ from 'lodash';

const useComputedTranscription = (rawTextJSON, rawTranslation) => {
  const currentTheme = useColorSchemeDetector();
  if (_.isEmpty(rawTextJSON)) {
    return null;
  }
  const { entitiesColors } = currentTheme.HIGHCHARTS;
  let translatedSentences = [];
  if (!_.isEmpty(rawTranslation) && rawTranslation.indexOf('\n') !== -1) {
    translatedSentences = rawTranslation.split('\n');
  }
  const transcription = {
    speakers: {},
  };
  const rawJSON = JSON.parse(rawTextJSON);
  transcription.sentences = _.map(rawJSON, (sentence, index) => {
    if (!transcription.speakers[sentence.spkid]) {
      const speakersLength = _.size(transcription.speakers);
      let speakerColor;
      if (speakersLength < 10) {
        speakerColor = entitiesColors[speakersLength];
      } else {
        speakerColor = getRandomColor(speakersLength, 'hexa');
      }
      transcription.speakers[sentence.spkid] = speakerColor;
    }

    return {
      ...sentence,
      translation: translatedSentences.length === rawJSON.length ? translatedSentences[index] : null,
    };
  });
  return transcription;
};

// eslint-disable-next-line import/prefer-default-export
export { useComputedTranscription };
