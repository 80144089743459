import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  Box,
  Card,
  CardMedia,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Virtuoso } from 'react-virtuoso';

import { clearCheckedSentences } from 'generic/core/ux/actions';
import { openPatchSpeakerDialog } from 'generic/core/ged/actions';
import { fetchResults } from 'generic/core/search/actions';
import ImageDetection from 'generic/components/ui/ImageDetection';
import TranscriptionSentence from 'generic/components/ui/TranscriptionSentence';
import Waveform from 'generic/components/ui/WaveSurfer';

const MediaWithTranscription = ({
  mediaType,
  mediaUrl,
  faces = [],
  frames = [],
  patchDocumentInComplete,
  displayTranslatedSentences = false,
  transcription = [],
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mediaRef = useRef(null);
  const virtuoso = useRef(null);
  const manualUserScroll = useRef(false);
  const manualUserEdition = useRef(false);
  const mediaWasPlayingBeforeEdition = useRef(false);
  const [currentMediaTime, setCurrentMediaTime] = useState(0);
  const [shownSpeakers, setShownSpeakers] = useState(_.keys(transcription.speakers));
  const [displayedSentences, setDisplayedSentences] = useState(transcription.sentences);

  const waveRegions = [];
  _.each(transcription.sentences, (sentence) => {
    if (shownSpeakers.includes(sentence.spkid)) {
      waveRegions.push({
        start: sentence.start,
        end: sentence.end,
        color: `${transcription.speakers[sentence.spkid]}50`,
      });
    }
  });

  useEffect(() => {
    // Si les speakers changent (après un renommage), on ré-affiche tout
    setShownSpeakers(_.keys(transcription.speakers));
    setDisplayedSentences(transcription.sentences);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcription.speakers]);

  const handleToggleFilterOnSpeaker = (event) => {
    const {
      target: { value, checked },
    } = event;
    const shownSpeakersFinal = [...shownSpeakers];
    if (!checked) {
      _.pull(shownSpeakersFinal, value);
    } else {
      shownSpeakersFinal.push(value);
    }
    setShownSpeakers(shownSpeakersFinal);
    const displayedSentencesFinal = [];
    let countHidden = 0;
    _.forEach(
      transcription.sentences,
      (sentence) => {
        if (shownSpeakersFinal.includes(sentence.spkid)) {
          displayedSentencesFinal.push(sentence);
          countHidden = 0;
        } else {
          if (countHidden === 0) {
            displayedSentencesFinal.push({ ellipsis: true });
          }
          countHidden++;
          _.last(displayedSentencesFinal).countHidden = countHidden;
        }
      },
    );
    setDisplayedSentences(displayedSentencesFinal);
  };

  let manualScrollTimeout = null;
  const handleManualScroll = () => {
    clearTimeout(manualScrollTimeout);
    manualUserScroll.current = true;
    manualScrollTimeout = setTimeout(() => {
      manualUserScroll.current = false;
    }, 3000);
  };

  const handlePlayOrPauseMediaWithSpace = (event) => {
    const { activeElement } = document;
    const isInputField = activeElement.tagName === 'INPUT'
      || activeElement.tagName === 'TEXTAREA'
      || activeElement.getAttribute('contenteditable') === 'true';

    if (!isInputField && mediaRef.current && !manualUserEdition.current && event.key === ' ') {
      if (!mediaRef.current.paused) {
        mediaRef.current.pause();
      } else {
        mediaRef.current.play();
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (mediaRef.current) {
      document.getElementById('transcription').addEventListener('wheel', handleManualScroll);
      document.addEventListener('keydown', handlePlayOrPauseMediaWithSpace);
    }
    return () => {
      dispatch(clearCheckedSentences());
      if (document.getElementById('transcription')) {
        document.getElementById('transcription').removeEventListener('wheel', handleManualScroll);
      }
      document.removeEventListener('keydown', handlePlayOrPauseMediaWithSpace);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaRef]);

  const handleTranscriptClick = useCallback((time) => {
    if (mediaRef.current) {
      manualUserScroll.current = false;
      manualUserEdition.current = false;
      mediaRef.current.currentTime = time;
      if (!mediaRef.current.paused) {
        mediaRef.current.play();
      }
    }
  }, [mediaRef]);

  const handleTimeUpdate = () => {
    if (mediaRef.current && !manualUserScroll.current && !manualUserEdition.current) {
      const { currentTime } = mediaRef.current;
      setCurrentMediaTime(currentTime);

      // Trouver le texte associé au moment actuel
      const activeItemIndex = displayedSentences.findIndex((item, index) => {
        const nextItem = displayedSentences[index + 1];
        if (!item.start) {
          return false;
        }
        return currentTime >= parseFloat(item.start) && (!nextItem || currentTime < parseFloat(nextItem.start));
      });

      // Faire défiler vers le texte actif
      if (activeItemIndex !== -1) {
        virtuoso.current.scrollToIndex({
          index: activeItemIndex,
          offset: -100,
          behavior: 'instant',
        });
      }
    }
  };

  const toggleManualUserEdition = useCallback((toggle) => {
    manualUserEdition.current = toggle;
    if (toggle && !mediaRef.current.paused) {
      mediaRef.current.pause();
      mediaWasPlayingBeforeEdition.current = true;
    } else if (!toggle) {
      if (mediaWasPlayingBeforeEdition.current) {
        mediaRef.current.play();
      }
      mediaWasPlayingBeforeEdition.current = false;
    }
  }, [mediaRef]);

  const handleImageClick = (time) => {
    if (mediaRef.current) {
      mediaRef.current.currentTime = time;
      mediaRef.current.play();
    }
  };

  const handleOpenPatchSpeakerDialog = useCallback((speakerId, sentenceStart) => {
    mediaRef.current.pause();
    dispatch(openPatchSpeakerDialog(
      speakerId,
      sentenceStart,
      _.without(_.keys(transcription.speakers), speakerId),
    ));
  }, [dispatch, transcription.speakers]);

  const handleSimilarityImage = (idChamp) => (itemVector) => {
    const nameChamp = `F_${idChamp}`;
    dispatch(fetchResults({
      bodyItems: {
        premier: 1,
        dernier: 20,
        base: 22801201,
        champs: {
          [nameChamp]: itemVector,
        },
      },
      refreshForm: true,
      clearSelection: true,
      clearResults: true,
    }));
  };

  return (
    <Fragment>
      {/* LECTEUR AUDIO */}
      {mediaType === 'audio' && (
        <Box sx={{ mr: 2 }}>
          <CardMedia
            sx={{
              maxWidth: '500px',
              mt: 2,
              mb: 2,
              flexShrink: 0,
            }}
            component="audio"
            controls
            onTimeUpdate={handleTimeUpdate}
            src={mediaUrl}
            ref={mediaRef}
          />
          <Waveform mediaRef={mediaRef} regions={waveRegions} />
        </Box>
      )}
      {/* LECTEUR VIDEO */}
      <Box sx={{ display: 'flex', gap: '32px' }}>
        {mediaType === 'video' && (
          <Card
            sx={{
              maxWidth: '500px',
              maxHeight: '300px',
              mt: 2,
              mb: 0,
              flexShrink: 0,
            }}
          >
            <CardMedia
              sx={{
                '&.MuiCardMedia-root': {
                  maxHeight: '300px',
                },
              }}
              component="video"
              controls
              src={mediaUrl}
              ref={mediaRef}
              onTimeUpdate={handleTimeUpdate}
            />
          </Card>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            overflow: 'hidden',
          }}
        >
          {/* DETECTION SCENES */}
          {!_.isEmpty(frames) && (
            <ImageDetection
              data={frames}
              title={t('document.frames')}
              handleImageClick={handleImageClick}
            />
          )}
          {/* DETECTION IMAGE */}
          {!_.isEmpty(faces) && (
            <ImageDetection
              data={faces}
              title={t('document.faces')}
              handleImageClick={handleImageClick}
              handleSimilarityImage={handleSimilarityImage(1032000592)}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          whiteSpace: 'pre-wrap',
          lineHeight: '30px',
          clear: 'both',
          overflow: 'auto',
          flexGrow: '1',
        }}
        id="transcription"
      >
        <Virtuoso
          ref={virtuoso}
          data={displayedSentences}
          style={{ height: '100%' }}
          // eslint-disable-next-line react/no-unstable-nested-components
          itemContent={(index, sentence) => {
            let currentMediaTimeValue;
            if (currentMediaTime >= sentence.start && (!sentence.end || currentMediaTime < sentence.end)) {
              currentMediaTimeValue = currentMediaTime;
            }
            return (
              sentence.ellipsis ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    pr: 2,
                    my: 1,
                    opacity: 0.7,
                  }}
                  key={index}
                >
                  <Divider sx={{ flexBasis: '87px', borderStyle: 'dashed' }} />
                  <Box
                    mx={2}
                  >
                    <Typography color="text.neutral" fontSize="13px">
                      {t('document.hidden_intervention', { count: sentence.countHidden })}
                    </Typography>
                  </Box>
                  <Divider className="flexGrow1" sx={{ borderStyle: 'dashed' }} />
                </Box>
              ) : (
                <TranscriptionSentence
                  currentMediaTime={currentMediaTimeValue}
                  displayHours={parseFloat(_.last(transcription.sentences).start) >= 3600}
                  displayTranslatedSentence={displayTranslatedSentences}
                  handleOpenPatchSpeakerDialog={handleOpenPatchSpeakerDialog}
                  handleTranscriptClick={handleTranscriptClick}
                  index={index}
                  patchDocumentInComplete={patchDocumentInComplete}
                  sentence={sentence}
                  speakers={transcription.speakers}
                  toggleManualUserEdition={toggleManualUserEdition}
                  wasRead={currentMediaTime >= sentence.start}
                />
              )
            );
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: 2,
          width: 'calc(100% - 60px)',
          pl: '11px',
        }}
      >
        {_.map(
          transcription.speakers,
          (speakerColor, speakerId) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: '25px',
                mb: '4px',
              }}
              key={speakerId}
            >
              <FormControlLabel
                value={speakerId}
                sx={{ mr: '5px' }}
                onClick={(event) => handleToggleFilterOnSpeaker(event)}
                control={(
                  <Switch
                    size="small"
                    checked={shownSpeakers.includes(speakerId)}
                  />
                )}
                label={(
                  <Typography noWrap sx={{ color: speakerColor }}>
                    {speakerId}
                  </Typography>
                )}
              />
              <IconButton
                size="extraSmall"
                sx={{
                  height: '22px',
                  width: '22px',
                  '& svg': {
                    fontSize: '18px',
                  },
                }}
                onClick={() => handleOpenPatchSpeakerDialog(speakerId)}
              >
                <Edit />
              </IconButton>
            </Box>
          ),
        )}
      </Box>
    </Fragment>
  );
};

MediaWithTranscription.propTypes = {
  displayTranslatedSentences: PropTypes.bool,
  patchDocumentInComplete: PropTypes.func.isRequired,
  transcription: PropTypes.shape({
    sentences: PropTypes.arrayOf(PropTypes.shape({
      sentence: PropTypes.string,
      spkid: PropTypes.string,
      start: PropTypes.number,
      end: PropTypes.number,
      words: PropTypes.arrayOf(PropTypes.shape({
        confidence: PropTypes.number,
        start: PropTypes.number,
        word: PropTypes.string,
      })),
    })),
    speakers: PropTypes.shape(/* speakerId: speakerColor */),
  }),
  mediaType: PropTypes.oneOf(['audio', 'video']).isRequired,
  mediaUrl: PropTypes.string.isRequired,
  faces: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      time: PropTypes.string,
      vector: PropTypes.string,
    }),
  ),
  frames: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      time: PropTypes.string,
    }),
  ),
};

export default MediaWithTranscription;
