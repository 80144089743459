import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { TextField } from 'formik-mui';
import {
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';

const NewPasswordTextField = ({ innerRef = null, ...rest }) => (
  <TextField
    sx={(theme) => ({
      borderRadius: '4px',
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : 'unset',
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#ced4da' : 'rgba(255, 255, 255, 0.23)',
      fontWeight: 'bold',
      width: '100%',

      '& > .MuiFormHelperText-root': {
        backgroundColor: theme.palette.primary.transparent,
        padding: '.25em 0 .25em .75em',
      },
    })}
    InputProps={{
      disableUnderline: true,
      inputProps: {
        ref: innerRef,
        sx: {
          padding: '.25em 0 .25em .75em',
        },
        autocomplete: 'new-password',
      },
    }}
    variant="standard"
    {...rest}
  />
);

NewPasswordTextField.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

const LoadingButtonWithIndicator = ({ ...props }) => (
  <LoadingButton
    sx={{ minWidth: '180px' }}
    color="secondary"
    loadingIndicator={<CircularProgress color="primary" size={16} />}
    {...props}
  />
);

const FormResetPasswordConfirmation = ({ loading }) => {
  const { t } = useTranslation();

  const usernameInput = useRef(null);

  return (
    <Grid
      container
      direction="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'space-around',
        minHeight: '30vh',
      }}
    >
      <Grid
        item
        container
        direction="column"
        sx={{ alignItems: 'center' }}
      >
        <Typography variant="h3">
          {t('logon.reset_password_confirmation_title')}
        </Typography>

        <Typography
          variant="subtitle2"
          align="center"
        >
          {t('logon.reset_password_confirmation_description')}
        </Typography>
      </Grid>

      <Grid
        item
        container
        direction="column"
        sx={{ justifyContent: 'center' }}
      >
        <Field name="a" type="hidden" />

        <Box sx={{ mb: 1 }}>
          <Field
            autoFocus
            component={NewPasswordTextField}
            name="newPassword"
            placeholder={t('logon.password')}
            innerRef={usernameInput}
            required
            type="password"
          />
        </Box>

        <Box sx={{ mb: 1 }}>
          <Field
            component={NewPasswordTextField}
            name="newPasswordConfirmation"
            placeholder={t('logon.password_confirmation')}
            required
            type="password"
          />
        </Box>
      </Grid>

      <Grid
        container
        direction="row"
        item
        sx={{
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <div>
          <LoadingButtonWithIndicator
            disabled={loading}
            loading={loading}
            type="submit"
          >
            {t('form.send')}
          </LoadingButtonWithIndicator>
        </div>
      </Grid>

    </Grid>
  );
};

FormResetPasswordConfirmation.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default FormResetPasswordConfirmation;
