import React, {
  Fragment, useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import queryString from 'query-string';
import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  Close,
  FormatListBulleted,
  SavedSearch,
  QueryStats,
  QuestionMark,
  Upload,
} from '@mui/icons-material';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { fastGedOpen } from 'generic/core/ged/actions';
import { openDialogUploadFiles } from 'generic/core/actions/actions';
import { cleanupResults } from 'generic/core/search/actions';
import SearchContainer from 'generic/containers/SearchContainer';
import HeaderMenuItem from 'generic/components/ui/HeaderMenuItem';
import UploadFilesDialogContainer from 'generic/containers/UploadFilesDialogContainer';
import { toggleIsRAGInputOpened } from 'generic/core/ux/actions';

const HeaderSearchWrapper = ({ showSearchInput = true, headerBackgroundColorIsDark = true }) => {
  const { UPLOAD_FILE_SETTINGS } = QES_CONSTANTS;
  const RAGFieldId = useSelector((state) => state.config.activeBase.idChampRAG);
  const baseId = useSelector((state) => state.config.activeBase.base);
  const activeCollection = useSelector((state) => state.collections.activeCollection);
  const isRAGInputOpened = useSelector((state) => state.ux.isRAGInputOpened);
  const { dashboardId } = useParams();
  const location = useLocation();
  const locationParams = queryString.parse(_.get(location, 'search', ''));
  const [showSearch, setShowSearch] = useState(true);
  const [showRAG, setShowRAG] = useState(false);
  const { t } = useTranslation();
  const { replace } = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const handleFastGedOpen = useCallback(
    () => dispatch(fastGedOpen(null, UPLOAD_FILE_SETTINGS.BASE, 'header')),
    [UPLOAD_FILE_SETTINGS.BASE, dispatch],
  );

  const handleToggleRAG = useCallback(
    () => {
      dispatch(toggleIsRAGInputOpened(!isRAGInputOpened));
      if (Object.keys(locationParams).includes(`F_${RAGFieldId}`)) {
        replace(`/search/results/${baseId}`);
        dispatch(cleanupResults());
      }
    },
    [RAGFieldId, baseId, dispatch, isRAGInputOpened, locationParams, replace],
  );

  useEffect(() => {
    if (RAGFieldId && Object.keys(locationParams).includes(`F_${RAGFieldId}`)) {
      dispatch(toggleIsRAGInputOpened(true));
      setShowSearch(false);
      setShowRAG(true);
    }
  }, [dispatch, locationParams, RAGFieldId]);

  useEffect(() => {
    if (isRAGInputOpened) {
      setShowRAG(true);
      const timer = setTimeout(() => {
        setShowSearch(false);
      }, 800);
      return () => {
        clearTimeout(timer);
      };
    }
    setShowSearch(true);
    const timer = setTimeout(() => {
      setShowRAG(false);
    }, 800);
    return () => {
      clearTimeout(timer);
    };
  }, [isRAGInputOpened]);

  let activeDashboardPathId = '';
  if (dashboardId) {
    activeDashboardPathId = `/${dashboardId}`;
  }
  const handleOpenDialogUploadFiles = useCallback(
    () => dispatch(openDialogUploadFiles(activeCollection.collection)),
    [dispatch, activeCollection],
  );

  const computeUploadFileBtn = useMemo(() => {
    const handleClick = UPLOAD_FILE_SETTINGS.BY_GED ? handleFastGedOpen : handleOpenDialogUploadFiles;

    return (
      <Fragment>
        <Tooltip title={t('header.upload_documents')}>
          <IconButton
            onClick={handleClick}
            sx={{
              ml: 1,
              height: '40px',
              color: headerBackgroundColorIsDark ? 'common.white' : 'common.black',
              borderRadius: '20px',
              backgroundColor: headerBackgroundColorIsDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.1)',
              zIndex: 2,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: headerBackgroundColorIsDark ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.2)',
              },
            }}
          >
            <Upload />
          </IconButton>
        </Tooltip>
        {!UPLOAD_FILE_SETTINGS.BY_GED && (
          <UploadFilesDialogContainer
            baseId={UPLOAD_FILE_SETTINGS.BASE}
            baseMisePageId={UPLOAD_FILE_SETTINGS.BASE_MISE_PAGE}
          />
        )}
      </Fragment>
    );
  }, [UPLOAD_FILE_SETTINGS, handleFastGedOpen, handleOpenDialogUploadFiles, headerBackgroundColorIsDark, t]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: '5px',
        flexGrow: '1',
        maxWidth: '740px',
        mx: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: '1',
          opacity: !isRAGInputOpened ? 1 : 0,
          transform: !isRAGInputOpened ? 'initial' : 'scaleX(0)',
          transformOrigin: 'center left',
          transition: 'transform 0.8s, opacity 0.8s',
        }}
      >
        {showSearch && (
          <Box
            sx={{
              display: 'flex',
              flexGrow: '1',
              columnGap: '5px',
            }}
          >
            <Box
              sx={{
                display: showSearchInput ? 'block' : 'none',
                flexGrow: '1',
              }}
            >
              <SearchContainer
                version="advanced"
                isVisible={showSearchInput}
              />
            </Box>
            {!smallerThanLarge && (
              <Fragment>
                <Tooltip
                  title={t('header.dashboard')}
                >
                  <HeaderMenuItem
                    component={NavLink}
                    to={`/search/dashboard/${baseId}${activeDashboardPathId}${location?.search || ''}`}
                    selected={location.pathname.startsWith('/search/dashboard')}
                    sx={{
                      textAlign: 'center',
                      p: '6px',
                      ml: 0.5,
                    }}
                  >
                    <QueryStats />
                  </HeaderMenuItem>
                </Tooltip>
                <Divider orientation="vertical" color={headerBackgroundColorIsDark ? '#fff' : '#000'} flexItem />
                <Tooltip
                  title={t('header.results')}
                >
                  <HeaderMenuItem
                    component={NavLink}
                    to={`/search/results/${baseId}${activeDashboardPathId}${location?.search || ''}`}
                    selected={location.pathname.startsWith('/search/results')}
                    sx={{
                      textAlign: 'center',
                      p: '6px',
                    }}
                  >
                    <FormatListBulleted />
                  </HeaderMenuItem>
                </Tooltip>
              </Fragment>
            )}
          </Box>
        )}
      </Box>

      {RAGFieldId && (
        <Fragment>
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              width: '95%',
              opacity: +isRAGInputOpened,
              transform: isRAGInputOpened ? 'scaleX(1)' : 'scaleX(0)',
              transformOrigin: 'center right',
              transition: ' transform 0.8s, opacity 0.8s',
            }}
          >
            {showRAG && (
              <Box
                sx={{ display: showSearchInput ? 'block' : 'none' }}
              >
                <SearchContainer
                  version="simple"
                  uniqueFieldCode={`F_${RAGFieldId}`}
                  uniqueFieldPlaceholder={t('form.ask')}
                  uniqueFieldIcon={<QuestionMark />}
                />
              </Box>
            )}
          </Box>
          <Tooltip title={isRAGInputOpened ? t('header.switch_to_search') : t('header.switch_to_question')}>
            <IconButton
              onClick={handleToggleRAG}
              sx={{
                display: showSearchInput ? 'block' : 'none',
                ml: 1,
                height: '40px',
                color: headerBackgroundColorIsDark ? 'common.white' : 'common.black',
                borderRadius: '20px',
                backgroundColor: headerBackgroundColorIsDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.1)',
                zIndex: 2,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: headerBackgroundColorIsDark ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.2)',
                },
              }}
            >
              {isRAGInputOpened ? <Close /> : <SavedSearch />}
            </IconButton>
          </Tooltip>
        </Fragment>
      )}

      {UPLOAD_FILE_SETTINGS.ENABLE && computeUploadFileBtn}
    </Box>
  );
};

HeaderSearchWrapper.propTypes = {
  showSearchInput: PropTypes.bool,
  headerBackgroundColorIsDark: PropTypes.bool,
};

export default HeaderSearchWrapper;
