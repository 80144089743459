import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useSingleAndDoubleClick } from 'generic/core/hooks/useSingleAndDoubleClick';

const TranscriptionWord = ({
  handleTranscriptClick,
  highlight = false,
  preventClick = false,
  wasRead = false,
  word,
}) => {
  // Le hook useSingleAndDoubleClick permet de définir une action pour le simple ET le
  // double clic. Ici on veut déclencher une action UNIQUEMENT sur le simple clic (donc
  // on ne définit rien pour le second paramètre)
  const clicks = useSingleAndDoubleClick(() => {
    if (!preventClick) {
      handleTranscriptClick(word.start);
    }
  });
  return (
    <Box
      component="span"
      onClick={clicks}
      start={word.start}
      sx={(theme) => ({
        height: '28px',
        display: 'inline-block',
        lineHeight: '30px',
        ...((word.confidence < 0.3 || word.confidence > 1) && {
          borderBottom: '1px solid',
          borderColor: word.confidence < 0.3 ? '#FF9900' : '#5CBF50',
        }),
        color: `${theme.palette.text.primary}${wasRead ? 'ff' : '80'}`,
        backgroundColor: highlight ? theme.palette.primary.transparent : 'transparent',
      })}
    >
      {`${word.word.trim()} `}
    </Box>
  );
};

TranscriptionWord.propTypes = {
  handleTranscriptClick: PropTypes.func.isRequired,
  highlight: PropTypes.bool,
  preventClick: PropTypes.bool,
  wasRead: PropTypes.bool,
  word: PropTypes.shape({
    confidence: PropTypes.number,
    start: PropTypes.number,
    word: PropTypes.string,
  }).isRequired,
};

export default React.memo(TranscriptionWord);
