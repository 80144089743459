import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import QES_CONSTANTS from 'generic/core/qes/constants';

import Collections from 'generic/components/pages/Collections';

import CollectionDialogContainer from 'generic/containers/CollectionDialogContainer';
import UploadFilesDialogContainer from 'generic/containers/UploadFilesDialogContainer';

import {
  activateCollection,
  openCollectionDialog,
  types,
} from 'generic/core/collections/actions';

import { openDialogUploadFiles } from 'generic/core/actions/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';

const CollectionsContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { UPLOAD_FILE_SETTINGS } = QES_CONSTANTS;

  const [inputSearch, setInputSearch] = useState('');

  const idUser = useSelector((state) => state.config.user.utilisateur);
  const collections = useSelector((state) => state.collections.collections);

  const collectionsSortedByName = _.sortBy(
    _.cloneDeep(collections),
    (collection) => collection.libelle.toLowerCase(),
  );

  /* Collections filtrées */
  const filteredCollections = [];
  _.forEach(collectionsSortedByName, (collection) => {
    let keepCollection = false;
    if (_.includes(collection.libelle.toLowerCase(), inputSearch.toLowerCase())) {
      keepCollection = true;
    }
    const filteredCorpora = [];
    _.forEach(collection.corpora, (corpus) => {
      const corpusPath = `${collection.libelle}/${corpus.libelle}`;
      if (keepCollection || _.includes(corpusPath.toLowerCase(), inputSearch.toLowerCase())) {
        filteredCorpora.push(corpus);
      }
    });
    if (keepCollection || filteredCorpora.length > 0) {
      filteredCollections.push({
        ...collection,
        corpora: filteredCorpora,
      });
    }
  });

  /* Si notre liste de corpus contient au moins 4 corpus,
  on insère dans un tableau les 4 dernières collections activées */
  let lastestActivedCorpora = _.flatMap(collections, 'corpora').length >= 4
    ? _.take(_.orderBy(
      _.flatMap(collections, 'corpora'),
      [(item) => item.date_dernier_acces || ''],
      ['desc'],
    ), 4) : [];

  lastestActivedCorpora = _.map(lastestActivedCorpora, (corpus) => ({
    ...corpus,
    collection_parente_name: _.find(collections, { collection: corpus.collection_parente }).libelle,
  }));

  const handleActivateCollection = (id) => {
    dispatch(activateCollection(id));
  };

  const handleOpenCollectionDialog = (typeRessource, mode, formValues) => {
    dispatch(openCollectionDialog(typeRessource, mode, formValues));
  };

  const handleOpenDeleteCollectionConfirmDialog = (typeRessource, ids) => {
    dispatch(setConfirmDialogData({
      title: t(`collections.${typeRessource}.delete_dialog`, { count: ids.length }),
      message: (
        <div>
          {t(`collections.${typeRessource}.delete`, { count: ids.length })}
          {typeRessource === 'corpus' ? (
            <ul>
              {_.map(ids, (id) => (
                <li key={id}>{_.find(_.flatMap(collections, 'corpora'), { collection: id }).libelle}</li>))}
            </ul>
          ) : (
            <ul>
              {_.map(ids, (id) => <li key={id}>{_.find(collections, { collection: id }).libelle}</li>)}
            </ul>
          )}
        </div>
      ),
      submitColor: 'error',
      waitForActions: [types.DELETE_COLLECTIONS_SUCCESS, types.DELETE_COLLECTIONS_ERROR],
      action: {
        type: types.DELETE_COLLECTIONS,
        ids,
        typeRessource,
      },
    }));
    return false;
  };

  const handleOpenDialogUploadFiles = (idCorpus) => {
    dispatch(openDialogUploadFiles(idCorpus));
  };

  return (
    <Fragment>
      <Collections
        collections={filteredCollections}
        hasCollectionOwner={_.some(collections, { utilisateur: idUser })}
        idUser={idUser}
        inputSearch={inputSearch}
        lastestActivedCorpora={lastestActivedCorpora}
        totalCollectionsLength={_.flatMap(collections, 'corpora').length}
        handleActivateCollection={handleActivateCollection}
        handleChangeInputSearch={setInputSearch}
        handleOpenCollectionDialog={handleOpenCollectionDialog}
        handleOpenDeleteCollectionConfirmDialog={handleOpenDeleteCollectionConfirmDialog}
        handleOpenDialogUploadFiles={handleOpenDialogUploadFiles}
      />
      <CollectionDialogContainer
        collections={_.sortBy(collections, (coll) => coll.libelle.toLowerCase())}
        idUser={idUser}
      />
      <UploadFilesDialogContainer
        baseId={UPLOAD_FILE_SETTINGS.BASE}
        baseMisePageId={UPLOAD_FILE_SETTINGS.BASE_MISE_PAGE}
      />
    </Fragment>
  );
};

export default CollectionsContainer;
