/* eslint-disable react/no-unstable-nested-components */
import React, { Fragment, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { fetchResults, fetchResultsComplete, refineAddFacetsValues } from 'generic/core/search/actions';
import {
  fetchDashboards,
  fetchWidgets,
  openDialogCreateWidget,
  openDialogEditOrCreateDashboard,
  saveWidgets,
  setContextMenu,
  setDashboardLoading,
  types,
} from 'generic/core/dashboard/actions';
import { snackActions } from 'generic/utils/snackbar';
import Highcharts from 'highcharts';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import {
  getColorForFrequency,
  getRandomColor,
  getToneColor,
  monoColors,
} from 'generic/utils/colorUtils';
import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  LockOutlined,
  OpenInNewOutlined,
} from '@mui/icons-material';
import { useTheme } from '@emotion/react';

import { format } from 'generic/utils/dateUtils';
import { fastGedOpen } from 'generic/core/ged/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import {
  getActiveBaseDashboardParams,
  getFieldIdFromFormFields,
  getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';
import goToAlienVault from 'generic/utils/alienVault';
import goToMitre from 'generic/utils/mitreUtils';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';
import Bar from 'generic/components/dashboard-items/Bar';
import ColumnHisto from 'generic/components/dashboard-items/ColumnHisto';
import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';
import Datatable from 'generic/components/dashboard-items/Datatable';
import DocumentList from 'generic/components/dashboard-items/DocumentList';
import Map from 'generic/components/dashboard-items/Map';
import NetworkGraph from 'generic/components/dashboard-items/NetworkGraph';
import Pie from 'generic/components/dashboard-items/Pie';
import Sankey from 'generic/components/dashboard-items/Sankey';
import SolidGauge from 'generic/components/dashboard-items/SolidGauge';
import Sunburst from 'generic/components/dashboard-items/Sunburst';
import Treemap from 'generic/components/dashboard-items/Treemap';
import WidgetContainer from 'generic/containers/WidgetContainer';
import Wordcloud from 'generic/components/dashboard-items/Wordcloud';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import CreateWidgetDialogContainer from 'generic/containers/CreateWidgetDialogContainer';
import DashboardFooter from 'generic/components/ui/DashboardFooter';
import ContextMenu from 'generic/components/dashboard-items/highchart-extensions/ContextMenu';
import QES_CONSTANTS from 'generic/core/qes/constants';

const ResponsiveGridLayout = WidthProvider(Responsive);

const {
  DATE_INTERVAL_COMPARATOR,
  DYNAMIC_DASHBOARD,
} = QES_CONSTANTS;

const computeTitle = (title, serie) => {
  let finalTitle = title || '';
  if (!_.isEmpty(serie)) {
    const { totalUnique } = getValueOrFirstValueFromArray(serie);
    if (totalUnique) {
      finalTitle = `${finalTitle} (${totalUnique})`;
    }
  }

  return finalTitle;
};

const generateDatatableFirstCell = (dispatch, fields, fieldName, computeLink) => (value) => (
  <Box sx={{ display: 'flex', cursor: 'pointer', alignItems: 'flex-start' }}>
    <IconButton
      size="small"
      onClick={
        () => { window.open(computeLink(value), '_blank'); }
      }
      sx={{
        padding: 0,
        marginRight: 1,
        '& .MuiSvgIcon-root': {
          color: 'primary.attenuated',
        },
        '&:hover .MuiSvgIcon-root': {
          color: 'primary.main',
        },
      }}
    >
      <OpenInNewOutlined fontSize="small" />
    </IconButton>
    <Typography
      sx={{
        width: '240px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: '14px',
      }}
      onClick={(event) => {
        dispatch(refineAddFacetsValues([
          {
            champ: getFieldIdFromFormFields(fields, fieldName),
            strategie: value,
          },
        ], (event.ctrlKey || event.metaKey)));
      }}
      onContextMenu={(event) => {
        event.preventDefault();
        dispatch(setContextMenu({
          x: event.pageX,
          y: event.pageY,
          filterTitle: value,
          facetsValues: [{
            champ: getFieldIdFromFormFields(fields, fieldName),
            strategie: value,
          }],
        }));
      }}
      title={value}
    >
      {value}
    </Typography>
  </Box>
);

const DashboardChartsContainer = () => {
  const currentTheme = useColorSchemeDetector();
  const user = useSelector((state) => state.config.user);
  const { entitiesColors, relationsEntitiesColors } = currentTheme.HIGHCHARTS;
  const strategy = useSelector((state) => state.search.results.strategie);
  const {
    base: activeBaseId,
    champs: fields,
    tranche: activeBaseDefaultTranche,
    tranches: activeBaseTranches,
  } = useSelector((state) => state.config.activeBase);
  const dashboards = useSelector((state) => state.dashboard.dashboards);
  const currentDashboards = _.filter(dashboards, { base: activeBaseId });
  const loadingDashboards = useSelector((state) => state.dashboard.loadingDashboards);
  const { push } = useHistory();
  const location = useLocation();
  const { dashboardId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const scrollContainer = useRef();
  Highcharts.setOptions(currentTheme.HIGHCHARTS);

  const {
    COOCCURRENCES_FIELDS,
    RELATIONS_NODES_FIELDS,
    RELATIONS_LINKS_FIELDS,
  } = getActiveBaseDashboardParams(activeBaseId);

  let currentDashboard = {};
  if (dashboardId) {
    currentDashboard = _.find(currentDashboards, { dashboard: +dashboardId });
  }

  if (_.isEmpty(currentDashboard) && !_.isEmpty(currentDashboards)) {
    [currentDashboard] = currentDashboards;
  }

  let canEditDashboard = currentDashboard?.utilisateur === user.utilisateur || user.admin;
  if (smallerThanLarge || !DYNAMIC_DASHBOARD) {
    canEditDashboard = false;
  }

  const WIDGETS = currentDashboard?.dashboard_widget || [];

  let cooccurrenceFields = _.map(
    COOCCURRENCES_FIELDS,
    (field, index) => ({
      ...field,
      color: entitiesColors[index],
    }),
  );

  let relationsNodesFields = _.map(
    RELATIONS_NODES_FIELDS,
    (field, index) => ({
      ...field,
      color: RELATIONS_NODES_FIELDS.length > 15 ? getRandomColor(index) : relationsEntitiesColors[index],
    }),
  );

  let relationsLinksFields = _.map(
    RELATIONS_LINKS_FIELDS,
    (field, index) => ({
      ...field,
      color: getRandomColor(index),
    }),
  );
  // On utilise ici une ref pour pouvoir la mettre à jour
  // depuis le widget cooccurrences sans re-render tout le
  // dashboard
  let activeCooccurrencesFields = [];
  if (cooccurrenceFields) {
    activeCooccurrencesFields = _.map(_.filter(cooccurrenceFields, 'active'), 'value');
  }
  const cooccurrencesNodesFilterValue = useRef(activeCooccurrencesFields);
  const cooccurrencesNodesFilterValueJoined = cooccurrencesNodesFilterValue.current.join(',');

  let activeRelationsNodesFields = [];
  if (relationsNodesFields) {
    activeRelationsNodesFields = _.map(_.filter(relationsNodesFields, 'active'), 'value');
  }
  const relationsNodesFilterValue = useRef(activeRelationsNodesFields);
  let activeRelationsLinksFields = [];
  if (relationsLinksFields) {
    activeRelationsLinksFields = _.map(_.filter(relationsLinksFields, 'active'), 'value');
  }
  const relationsLinksFilterValue = useRef(activeRelationsLinksFields);
  const relationsLinksFilterValueJoined = relationsLinksFilterValue.current.join(',');

  const buildNodesTypesAdditionalQuery = () => {
    const nodesTypesSources = [];
    const nodesTypesDestinations = [];
    _.forEach(
      relationsNodesFilterValue.current,
      (nodeTypeValue) => {
        nodesTypesSources.push(
          `QES_Relation_Source_Type:${nodeTypeValue}`,
        );
        nodesTypesDestinations.push(
          `QES_Relation_Destination_Type:${nodeTypeValue}`,
        );
      },
    );
    let additionalQuery = '';
    if (nodesTypesSources) {
      additionalQuery = `(${nodesTypesSources.join(' OR ')}) AND (${nodesTypesDestinations.join(' OR ')})`;
    }
    return additionalQuery;
  };

  let tranche = activeBaseDefaultTranche;
  const localStorageTranche = +localStorage.getItem('rowsPerPage');
  if (!_.isEmpty(activeBaseTranches)
    && activeBaseTranches.includes(localStorageTranche)) {
    tranche = localStorageTranche;
  }

  const widgets = _.sortBy(_.map(
    WIDGETS,
    (widget) => {
      const widgetJsonParams = JSON.parse(widget.json);
      const finalWidget = {
        ...widget,
        ...widgetJsonParams,
      };
      if (finalWidget.type === 'relations') {
        finalWidget.relations = relationsLinksFilterValueJoined;
        finalWidget.aggregates = [relationsLinksFilterValueJoined];
        finalWidget.additionalQuery = buildNodesTypesAdditionalQuery();
      } else if (finalWidget.type === 'cooccurrences') {
        finalWidget.facets = cooccurrencesNodesFilterValueJoined;
        finalWidget.aggregates = [cooccurrencesNodesFilterValueJoined];
      }
      return finalWidget;
    },
  ), ['pos_y', 'pos_x']);

  useEffect(() => {
    if (loadingDashboards) {
      dispatch(fetchDashboards());
    }
  }, [dispatch, loadingDashboards]);

  useEffect(() => {
    if (!_.isEmpty(strategy) && !_.isEmpty(currentDashboards) && !loadingDashboards) {
      dispatch(fetchWidgets(strategy, widgets));
    }

    // On ne met pas cooccurrencesNodesFilterValue dans les dépendances, pour
    // éviter de refresh tout le dashboard quand on change juste
    // le type des éléments affichés (refresh géré dans
    // handleChangeCooccurrenceValue).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, strategy, loadingDashboards, dashboardId]);

  const handleOpenDeleteDashboardDialog = () => {
    dispatch(setConfirmDialogData({
      title: t('dashboard.delete_dashboard'),
      textButton: 'form.delete',
      message: (
        <Trans>
          {t('dashboard.delete_dashboard_sure', { dashboardTitle: currentDashboard.libelle })}
        </Trans>
      ),
      submitColor: 'error',
      waitForActions: [types.DELETE_DASHBOARD_SUCCESS, types.DELETE_DASHBOARD_ERROR],
      action: {
        type: types.DELETE_DASHBOARD,
        dashboardId: currentDashboard.dashboard,
      },
    }));
  };

  const handleSaveLayout = (layout) => {
    const widgetsParams = _.map(
      layout,
      (item) => ({
        dashboard: currentDashboard.dashboard,
        dashboard_widget: +item.i,
        width: item.w,
        height: item.h,
        pos_x: item.x,
        pos_y: item.y,
      }),
    );
    // On delay un tout petit peu l'enregistrement pour éviter un lag lors de
    // l'animation CSS ;-)
    setTimeout(() => {
      dispatch(saveWidgets(widgetsParams, true));
    }, 300);
  };

  const getLinkColor = (linkItem) => {
    let linkColor = '#c5c5c5';
    if (theme.palette.dark === 'dark') {
      linkColor = '#dddddd';
    }
    if (linkItem) {
      if (linkItem.type === 'multipleLinks') {
        linkColor = theme.palette.mode === 'dark' ? '#ffffff' : '#000000';
      } else {
        linkColor = _.find(relationsLinksFields, { value: linkItem.type })?.color || linkColor;
      }
    }
    return linkColor;
  };

  const handleExportWidget = (widgetId) => {
    const keepAllWidgetsData = true;
    const params = _.find(widgets, { dashboard_widget: widgetId });
    params.facetmax = 100000;
    params.exportWidget = true;
    dispatch(fetchWidgets(strategy, [params], false, keepAllWidgetsData));
  };

  const renderWidget = (widgetParams) => {
    const dataGridParams = {
      x: widgetParams.pos_x,
      y: widgetParams.pos_y,
      w: widgetParams.width,
      h: widgetParams.height,
    };
    switch (widgetParams.type) {
      case 'spline':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  canEditDashboard={canEditDashboard}
                  component={ColumnHisto}
                  widgetParams={widgetParams}
                  highchartsOptions={{
                    options: {
                      tooltip: {
                        formatter: function tooltipFormatter() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${format(this.x)}: <b>${this.y}</b>`;
                        },
                      },
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: canEditDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                      series,
                      yAxis: {
                        type: 'logarithmic',
                        allowNegativeLog: true,
                        title: { text: '' },
                      },
                      legend: false,
                      xAxis: {
                        title: { text: '' },
                        startOnTick: true,
                        endOnTick: true,
                        gridLineWidth: 0,
                        dateTimeLabelFormats: {
                          second: '%d/%m/%Y<br/>%H:%M:%S',
                          minute: '%d/%m/%Y<br/>%H:%M',
                          hour: '%d/%m/%Y<br/>%H:%M',
                          day: '%Y<br/>%d/%m',
                          month: '%m/%Y',
                          year: '%Y',
                        },
                        events: {
                          afterSetExtremes: (event) => {
                            if (event.trigger === 'zoom') {
                              const min = series[0].data[0][0];
                              const max = series[0].data[
                                series[0].data.length - 1
                              ][0];
                              let valmin = event.min;
                              let valmax = event.max;
                              if (valmin < min) {
                                valmin = min;
                              }
                              if (valmax > max) {
                                valmax = max;
                              }
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                  formatFacet: 'date',
                                  comparator: DATE_INTERVAL_COMPARATOR.between,
                                  begin: format(new Date(valmin), 'yyyyMMdd'),
                                  end: format(new Date(valmax), 'yyyyMMdd'),
                                },
                              ]));
                            }
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'bar':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  canEditDashboard={canEditDashboard}
                  component={Bar}
                  widgetParams={widgetParams}
                  handleExportWidget={() => handleExportWidget(widgetParams.dashboard_widget)}
                  highchartsOptions={{
                    options: {
                      tooltip: {
                        enabled: false,
                      },
                      plotOptions: {
                        series: {
                          point: {
                            events: {
                              click: (event) => {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: event.point.name,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                              contextmenu: (event) => {
                                dispatch(setContextMenu({
                                  x: event.x,
                                  y: event.y,
                                  filterTitle: event.point.name,
                                  facetsValues: [{
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: event.point.name,
                                  }],
                                }));
                              },
                            },
                          },
                        },
                      },
                      legend: false,
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: canEditDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                      yAxis: {
                        title: {
                          text: '',
                        },
                      },
                      series,
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'map':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
            style={{ height: '100%', display: 'block' }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  canEditDashboard={canEditDashboard}
                  component={Map}
                  widgetParams={widgetParams}
                  highchartsOptions={{
                    options: {
                      plotOptions: {
                        series: {
                          tooltip: {
                            pointFormatter: function getTranslatedCountryName() {
                              // eslint-disable-next-line react/no-this-in-sfc
                              return `${t(`dashboard.countries.${this['iso-a2']}`)}: <b>${this.value}</b>`;
                            },
                          },
                          point: {
                            events: {
                              click: (event) => {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: event.point.options.name,
                                    dontQuoteStrategy: true,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                              contextmenu: (event) => {
                                dispatch(setContextMenu({
                                  x: event.x,
                                  y: event.y,
                                  filterTitle: t(`dashboard.countries.${event.point.options.name}`),
                                  facetsValues: [{
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: event.point.options.name,
                                    dontQuoteStrategy: true,
                                  }],
                                }));
                              },
                              mouseOver: (event) => {
                                if (event.target.value !== undefined) {
                                  const { element } = event.target.graphic;
                                  element.style.cursor = 'pointer';
                                }
                              },
                            },
                          },
                        },
                      },
                      series,
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: canEditDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'sunburst':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  canEditDashboard={canEditDashboard}
                  component={Sunburst}
                  widgetParams={widgetParams}
                  highchartsOptions={{
                    options: {
                      plotOptions: {
                        sunburst: {
                          point: {
                            events: {
                              click: (event) => {
                                const interrogationArray = _.map(additionalData.facets.split(','), _.trim);
                                if (event.point.name && event.point.node.isLeaf) {
                                  dispatch(refineAddFacetsValues([
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[1],
                                      ),
                                      strategie: event.point.name,
                                    },
                                  ], (event.ctrlKey || event.metaKey)));
                                } else if (event.shiftKey && event.point.node.index !== 0) {
                                  dispatch(refineAddFacetsValues([
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[0],
                                      ),
                                      strategie: event.point.node.id,
                                      dontQuoteStrategy: event.point.node.id.indexOf(' ') === -1,
                                    },
                                  ], (event.ctrlKey || event.metaKey)));
                                }
                              },
                              contextmenu: (event) => {
                                const interrogationArray = _.map(additionalData.facets.split(','), _.trim);
                                let strategie;
                                let champ;
                                let dontQuoteStrategy;
                                if (event.point.name && event.point.node.isLeaf) {
                                  strategie = event.point.name;
                                  champ = getFieldIdFromFormFields(fields, interrogationArray[1]);
                                } else if (event.point.node.index !== 0) {
                                  strategie = event.point.node.id;
                                  champ = getFieldIdFromFormFields(fields, interrogationArray[0]);
                                  dontQuoteStrategy = true;
                                }
                                dispatch(setContextMenu({
                                  x: event.x,
                                  y: event.y,
                                  filterTitle: event.point.name,
                                  facetsValues: [{
                                    champ,
                                    strategie,
                                    dontQuoteStrategy,
                                  }],
                                }));
                              },
                            },
                          },
                        },
                      },
                      series: [{
                        data: _.get(series, '[0].data', []),
                      }],
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: canEditDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'documentlist':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series }) => (
                <DashboardWidget
                  canEditDashboard={canEditDashboard}
                  component={DocumentList}
                  componentProps={{
                    title: {
                      text: computeTitle(widgetParams.title, series),
                      align: canEditDashboard ? 'center' : 'left',
                      margin: 20,
                    },
                    qesdocuments: _.get(series, '[0]', []),
                    handleDocumentClick: (documentIdext) => dispatch(fetchResultsComplete(documentIdext, activeBaseId)),
                    handleOpenFastGed: (documentIdext) => dispatch(fastGedOpen(documentIdext, activeBaseId, 'results')),
                  }}
                  widgetParams={widgetParams}

                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'pie':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
            // style={{ height: '100%' }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => {
                let data = _.get(series, '[0].data', []);
                if (widgetParams.tones) {
                  data = _.sortBy(_.get(series, '[0].data', []), 'name').map(
                    (item) => ({
                      ...item,
                      color: getToneColor(item.name),
                    }),
                  );
                }
                const additionnalOptions = {};
                if (widgetParams.monoColors) {
                  additionnalOptions.colors = monoColors(0.35, _.get(series, '[0].data.length', 15), 0);
                }
                return (
                  <DashboardWidget
                    canEditDashboard={canEditDashboard}
                    component={Pie}
                    widgetParams={widgetParams}
                    handleExportWidget={() => handleExportWidget(widgetParams.dashboard_widget)}
                    highchartsOptions={{
                      options: {
                        plotOptions: {
                          pie: {
                            data,
                            point: {
                              events: {
                                click: (event) => {
                                  dispatch(refineAddFacetsValues([
                                    {
                                      champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                      strategie: event.point.strategy,
                                    },
                                  ], (event.ctrlKey || event.metaKey)));
                                },
                                contextmenu: (event) => {
                                  dispatch(setContextMenu({
                                    x: event.x,
                                    y: event.y,
                                    filterTitle: event.point.strategy,
                                    facetsValues: [{
                                      champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                      strategie: event.point.strategy,
                                    }],
                                  }));
                                },
                              },
                            },
                          },
                        },
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: canEditDashboard ? 'center' : 'left',
                          margin: 20,
                        },
                        ...additionnalOptions,
                      },
                    }}
                  />
                );
              }}
            </WidgetContainer>
          </div>
        );
      case 'solidgauge':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({
                series,
                axisX,
                additionalData,
              }) => (
                <DashboardWidget
                  canEditDashboard={canEditDashboard}
                  component={SolidGauge}
                  widgetParams={widgetParams}
                  highchartsOptions={{
                    options: {
                      plotOptions: {
                        solidgauge: {
                          events: {
                            click: (event) => {
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                  strategie: event.point.name,
                                  dontQuoteStrategy: event.point.name.indexOf(' ') === -1,
                                },
                              ], (event.ctrlKey || event.metaKey)));
                            },
                            contextmenu: (event) => {
                              dispatch(setContextMenu({
                                x: event.x,
                                y: event.y,
                                filterTitle: widgetParams.humanizeDocState
                                  ? t(`ged.document.status.${event.point.name}`) : event.point.name,
                                facetsValues: [{
                                  champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                  strategie: event.point.name,
                                  dontQuoteStrategy: true,
                                }],
                              }));
                            },
                          },
                        },
                      },
                      pane: {
                        background: axisX.categories.map((aggregate, index) => ({
                          outerRadius: aggregate.outerRadius,
                          innerRadius: aggregate.innerRadius,
                          borderWidth: 0,
                          backgroundColor: `${Highcharts.getOptions().colors[index]}4D`,
                        })),
                      },
                      tooltip: {
                        enabled: false,
                      },
                      legend: {
                        align: 'center',
                        labelFormatter: function lf() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${this.name} - ${this.data[0].y}%`;
                        },
                      },
                      series: _.get(series, '[0].data', []).map((aggregate, index) => ({
                        name: aggregate.name,
                        data: aggregate.data.map((aggdata) => ({
                          radius: aggdata.radius,
                          innerRadius: aggdata.innerRadius,
                          name: aggdata.name,
                          y: aggdata.y,
                          color: Highcharts.getOptions().colors[index],
                        })),
                        color: Highcharts.getOptions().colors[index],
                        showInLegend: true,
                      })),
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: canEditDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'sankey':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  canEditDashboard={canEditDashboard}
                  component={Sankey}
                  widgetParams={widgetParams}
                  handleExportWidget={() => handleExportWidget(widgetParams.dashboard_widget)}
                  highchartsOptions={{
                    options: {
                      plotOptions: {
                        series: {
                          point: {
                            events: {
                              click: (event) => {
                                const interrogationArray = _.map(additionalData.facets.split(','), _.trim);
                                let conceptsPersonsFields;
                                if (event.point.from) {
                                  // On a cliqué sur un lien, donc on prépare le double raffinage
                                  conceptsPersonsFields = [
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[event.point.fromNode.column],
                                      ),
                                      strategie: event.point.fromNode.name,
                                    },
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[event.point.toNode.column],
                                      ),
                                      strategie: event.point.toNode.name,
                                    },
                                  ];
                                } else {
                                  // On a cliqué sur un noeud, on fait un simple raffinage
                                  conceptsPersonsFields = [
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[event.point.column],
                                      ),
                                      strategie: event.point.name,
                                    },
                                  ];
                                }
                                dispatch(refineAddFacetsValues(
                                  conceptsPersonsFields,
                                  (event.ctrlKey || event.metaKey),
                                ));
                              },
                              contextmenu: (event) => {
                                const interrogationArray = _.map(additionalData.facets.split(','), _.trim);
                                let conceptsPersonsFields;
                                let filterTitle;
                                if (event.point.from) {
                                  // On a cliqué sur un lien, donc on prépare le double raffinage
                                  filterTitle = `${event.point.fromNode.name} & ${event.point.toNode.name}`;
                                  conceptsPersonsFields = [
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[event.point.fromNode.column],
                                      ),
                                      strategie: event.point.fromNode.name,
                                    },
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[event.point.toNode.column],
                                      ),
                                      strategie: event.point.toNode.name,
                                    },
                                  ];
                                } else {
                                  // On a cliqué sur un noeud, on fait un simple raffinage
                                  filterTitle = event.point.name;
                                  conceptsPersonsFields = [
                                    {
                                      champ: getFieldIdFromFormFields(
                                        fields,
                                        interrogationArray[event.point.column],
                                      ),
                                      strategie: event.point.name,
                                    },
                                  ];
                                }
                                dispatch(setContextMenu({
                                  x: event.x,
                                  y: event.y,
                                  filterTitle,
                                  facetsValues: conceptsPersonsFields,
                                }));
                              },
                            },
                          },
                        },
                      },
                      series,
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: canEditDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'treemap':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  canEditDashboard={canEditDashboard}
                  component={Treemap}
                  widgetParams={widgetParams}
                  highchartsOptions={{
                    options: {
                      plotOptions: {
                        treemap: {
                          animation: false,
                          colorByPoint: true,
                          point: {
                            events: {
                              click: (event) => {
                                let itemValue = event.point.name;
                                if (widgetParams.humanizeDocState && additionalData.docsStates) {
                                  itemValue = additionalData.docsStates[event.point.name];
                                }
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: itemValue,
                                    dontQuoteStrategy: itemValue.indexOf(' ') === -1,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                              contextmenu: (event) => {
                                let itemValue = event.point.name;
                                if (widgetParams.humanizeDocState && additionalData.docsStates) {
                                  itemValue = additionalData.docsStates[event.point.name];
                                }
                                dispatch(setContextMenu({
                                  x: event.x,
                                  y: event.y,
                                  filterTitle: event.point.name,
                                  facetsValues: [{
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: itemValue,
                                    dontQuoteStrategy: itemValue.indexOf(' ') === -1,
                                  }],
                                }));
                              },
                            },
                          },
                        },
                      },
                      series,
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: canEditDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'cooccurrences':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, isResizable: false }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => {
                cooccurrenceFields = cooccurrenceFields.map(
                  (cooccurrenceField) => ({
                    ...cooccurrenceField,
                    nbNodes: _.get(additionalData?.nodeCounts, cooccurrenceField.value, 0),
                  }),
                );
                return (
                  <DashboardWidget
                    canEditDashboard={canEditDashboard}
                    component={NetworkGraph}
                    componentProps={{
                      nodesFilterValue: cooccurrencesNodesFilterValue.current,
                      nodesFilterItems: cooccurrenceFields,
                      handleRefreshChart: (filterValues) => {
                        if (_.isEmpty(filterValues)) {
                          snackActions.warning(t('dashboard.widget.at_least_or_node'));
                          return;
                        }
                        cooccurrencesNodesFilterValue.current = filterValues;
                        const keepOtherWidgetsData = true;
                        const joinedValue = filterValues.join(',');
                        dispatch(fetchWidgets(strategy, [
                          {
                            dashboard_widget: widgetParams.dashboard_widget,
                            facets: joinedValue,
                            aggregates: [joinedValue],
                            facetmax: 200,
                            facetmax2: 5,
                            mindoccount: 1,
                            type: 'cooccurrences',
                          },
                        ], keepOtherWidgetsData));
                      },
                      handleLinkClick: (event, fromNode, toNode) => {
                        const fromIdField = getFieldIdFromFormFields(fields, fromNode.options.group);
                        const toIdField = getFieldIdFromFormFields(fields, toNode.options.group);
                        const facetsValues = [{
                          champ: fromIdField,
                          strategie: fromNode.id,
                        }];
                        if (fromIdField === toIdField) {
                          // Si jamais l'ID de champ est le même pour les deux noeuds,
                          // on colle les deux valeurs dans un même tableau, qui sera
                          // découpé convenablement au moment du fetch des résultats
                          facetsValues[0].strategie = [fromNode.id, toNode.id];
                        } else {
                          // A l'inverse, si les ID de champs sont différents, on
                          // on ajoute une seconde "facetValue" avec les éléments
                          // du second noeud
                          facetsValues.push({
                            champ: toIdField,
                            strategie: toNode.id,
                          });
                        }
                        dispatch(refineAddFacetsValues(facetsValues, (event.ctrlKey || event.metaKey)));
                      },
                      handleLinkContextmenu: (event, fromNode, toNode) => {
                        const fromIdField = getFieldIdFromFormFields(fields, fromNode.options.group);
                        const toIdField = getFieldIdFromFormFields(fields, toNode.options.group);
                        const facetsValues = [{
                          champ: fromIdField,
                          strategie: fromNode.id,
                        }];
                        if (fromIdField === toIdField) {
                          // Si jamais l'ID de champ est le même pour les deux noeuds,
                          // on colle les deux valeurs dans un même tableau, qui sera
                          // découpé convenablement au moment du fetch des résultats
                          facetsValues[0].strategie = [fromNode.id, toNode.id];
                        } else {
                          // A l'inverse, si les ID de champs sont différents, on
                          // on ajoute une seconde "facetValue" avec les éléments
                          // du second noeud
                          facetsValues.push({
                            champ: toIdField,
                            strategie: toNode.id,
                          });
                        }
                        dispatch(setContextMenu({
                          x: event.x,
                          y: event.y,
                          filterTitle: `${fromNode.id} & ${toNode.id}`,
                          facetsValues,
                        }));
                      },
                    }}
                    widgetParams={widgetParams}
                    highchartsOptions={{
                      options: {
                        chart: {
                          height: 720,
                        },
                        plotOptions: {
                          series: {
                            point: {
                              events: {
                                click: (event) => {
                                  dispatch(refineAddFacetsValues([
                                    {
                                      champ: getFieldIdFromFormFields(fields, event.point.options.group),
                                      strategie: event.point.name,
                                    },
                                  ], (event.ctrlKey || event.metaKey)));
                                },
                                contextmenu: (event) => {
                                  dispatch(setContextMenu({
                                    x: event.x,
                                    y: event.y,
                                    filterTitle: event.point.name,
                                    facetsValues: [{
                                      champ: getFieldIdFromFormFields(fields, event.point.options.group),
                                      strategie: event.point.name,
                                    }],
                                  }));
                                },
                              },
                            },
                          },
                        },
                        series: [{
                          nodes: _.get(series, '[0].nodes', []).map(
                            (node) => ({
                              ...node,
                              color: _.find(cooccurrenceFields, { value: node.group })?.color,
                            }),
                          ),
                          data: _.get(series, '[0].data', []).map(
                            (link) => ({
                              ...link,
                              color: getLinkColor(),
                            }),
                          ),
                        }],
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: canEditDashboard ? 'center' : 'left',
                          margin: 20,
                        },
                      },
                    }}
                  />
                );
              }}
            </WidgetContainer>
          </div>
        );
      case 'relations':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, isResizable: false }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => {
                relationsLinksFields = relationsLinksFields.map(
                  (relationsLinksField) => ({
                    ...relationsLinksField,
                    nbLinks: _.get(additionalData?.linkCounts, relationsLinksField.value, 0),
                  }),
                );
                relationsNodesFields = relationsNodesFields.map(
                  (relationsNodesField) => ({
                    ...relationsNodesField,
                    nbNodes: _.get(additionalData?.nodeCounts, relationsNodesField.value, 0),
                  }),
                );
                return (
                  <DashboardWidget
                    canEditDashboard={canEditDashboard}
                    component={NetworkGraph}
                    componentProps={{
                      iterations: additionalData?.iterations,
                      withExploration: widgetParams.withExploration,
                      linksFilterValue: relationsLinksFilterValue.current,
                      linksFilterItems: _.orderBy(
                        relationsLinksFields,
                        ['nbLinks', (item) => relationsLinksFilterValue.current.indexOf(item.value) > -1, 'name'],
                        ['desc', 'desc', 'asc'],
                      ),
                      nodesFilterValue: relationsNodesFilterValue.current,
                      nodesFilterItems: _.orderBy(
                        relationsNodesFields,
                        ['nbNodes', 'name'],
                        ['desc', 'asc'],
                      ),
                      handleRefreshChart: (nodesTypesValues, linksTypesValues) => {
                        if (_.isEmpty(nodesTypesValues) || _.isEmpty(linksTypesValues)) {
                          snackActions.warning(t('dashboard.widget.at_least_one_link_or_node'));
                          return;
                        }
                        relationsNodesFilterValue.current = nodesTypesValues;
                        relationsLinksFilterValue.current = linksTypesValues;
                        const joinedTypesValue = linksTypesValues.join(',');

                        const keepOtherWidgetsData = true;
                        dispatch(fetchWidgets(
                          strategy,
                          [
                            {
                              dashboard_widget: widgetParams.dashboard_widget,
                              facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
                              relations: joinedTypesValue,
                              aggregates: [joinedTypesValue],
                              facetmax: 200,
                              facetmax2: 5,
                              mindoccount: 1,
                              additionalQuery: buildNodesTypesAdditionalQuery(),
                              type: 'relations',
                            },
                          ],
                          keepOtherWidgetsData,
                        ));
                      },
                      handleLinkClick: (event, fromNode, toNode, link) => {
                        const fromIdField = getFieldIdFromFormFields(fields, `QES_${fromNode.options.group}`);
                        const toIdField = getFieldIdFromFormFields(fields, `QES_${toNode.options.group}`);
                        const facetsValues = [{
                          champ: fromIdField,
                          strategie: fromNode.id,
                        }];
                        // Si jamais on clique sur un lien découvert par l'exploration,
                        // on va repartir d'une recherche vide, donc on refresh tout
                        const refreshFields = {
                          [`F_${fromIdField}`]: `"${_.trim(fromNode.id, ' "')}"`,
                        };
                        if (fromIdField === toIdField) {
                          // Si jamais l'ID de champ est le même pour les deux noeuds,
                          // on colle les deux valeurs dans un même tableau, qui sera
                          // découpé convenablement au moment du fetch des résultats
                          facetsValues[0].strategie = [fromNode.id, toNode.id];
                          refreshFields[`F_${fromIdField}`] = (
                            `"${_.trim(fromNode.id, ' "')}" AND "${_.trim(toNode.id, ' "')}"`
                          );
                        } else {
                          // A l'inverse, si les ID de champs sont différents, on
                          // on ajoute une seconde "facetValue" avec les éléments
                          // du second noeud
                          facetsValues.push({
                            champ: toIdField,
                            strategie: toNode.id,
                          });
                          refreshFields[`F_${toIdField}`] = `"${_.trim(toNode.id, ' "')}"`;
                        }
                        if (link.options.iteration) {
                          if (link.options.relatedLinks) {
                            relationsLinksFilterValue.current = [...new Set(
                              _.concat(
                                relationsLinksFilterValue.current,
                                _.map(link.options.relatedLinks, 'type'),
                              ),
                            )];
                          } else if (!relationsLinksFilterValue.current.includes(link.options.type)) {
                            relationsLinksFilterValue.current.push(link.options.type);
                          }
                          let additionnalFetchParams = {
                            refreshForm: true,
                            clearSelection: true,
                            clearResults: true,
                          };
                          if (event.ctrlKey || event.metaKey) {
                            additionnalFetchParams = {
                              quickResultsScope: true,
                            };
                          } else {
                            dispatch(setDashboardLoading());
                          }
                          dispatch(fetchResults({
                            bodyItems: {
                              premier: 1,
                              dernier: tranche,
                              base: activeBaseId,
                              champs: refreshFields,
                            },
                            ...additionnalFetchParams,
                          }));
                        } else {
                          dispatch(refineAddFacetsValues(facetsValues, (event.ctrlKey || event.metaKey)));
                        }
                      },
                      handleLinkContextmenu: (event, fromNode, toNode, link) => {
                        const fromIdField = getFieldIdFromFormFields(fields, `QES_${fromNode.options.group}`);
                        const toIdField = getFieldIdFromFormFields(fields, `QES_${toNode.options.group}`);
                        const facetsValues = [{
                          champ: fromIdField,
                          strategie: fromNode.id,
                        }];
                        // Si jamais on clique sur un lien découvert par l'exploration,
                        // on va repartir d'une recherche vide, donc on refresh tout
                        const refreshFields = {
                          [`F_${fromIdField}`]: `"${_.trim(fromNode.id, ' "')}"`,
                        };

                        if (fromIdField === toIdField) {
                          // Si jamais l'ID de champ est le même pour les deux noeuds,
                          // on colle les deux valeurs dans un même tableau, qui sera
                          // découpé convenablement au moment du fetch des résultats
                          facetsValues[0].strategie = [fromNode.id, toNode.id];
                          refreshFields[`F_${fromIdField}`] = (
                            `"${_.trim(fromNode.id, ' "')}" AND "${_.trim(toNode.id, ' "')}"`
                          );
                        } else {
                          // A l'inverse, si les ID de champs sont différents, on
                          // on ajoute une seconde "facetValue" avec les éléments
                          // du second noeud
                          facetsValues.push({
                            champ: toIdField,
                            strategie: toNode.id,
                          });
                          refreshFields[`F_${toIdField}`] = `"${_.trim(toNode.id, ' "')}"`;
                        }
                        dispatch(setContextMenu({
                          x: event.x,
                          y: event.y,
                          filterTitle: `${fromNode.id} & ${toNode.id}`,
                          facetsValues,
                          pointOptions: link.options,
                          iteration: link.options.iteration,
                          champs: refreshFields,
                        }));
                      },
                    }}
                    widgetParams={widgetParams}
                    highchartsOptions={{
                      options: {
                        chart: {
                          height: 720,
                        },
                        plotOptions: {
                          series: {
                            point: {
                              events: {
                                click: (event) => {
                                  const idField = getFieldIdFromFormFields(
                                    fields,
                                    `QES_${event.point.options.group}`,
                                  );
                                  if (widgetParams.withExploration && event.shiftKey) {
                                    const joinedTypesValue = relationsLinksFields.map(
                                      (field) => field.value,
                                    ).join(',');
                                    const keepAllWidgetsData = true;
                                    dispatch(fetchWidgets(
                                      `QES_Relation_Source_Text.verbatim:"${_.trim(event.point.name, ' "')}"`,
                                      [
                                        {
                                          dashboard_widget: widgetParams.dashboard_widget,
                                          // eslint-disable-next-line max-len
                                          facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
                                          relations: joinedTypesValue,
                                          aggregates: [joinedTypesValue],
                                          facetmax: 200,
                                          facetmax2: 5,
                                          mindoccount: 1,
                                          additionalQuery: buildNodesTypesAdditionalQuery(),
                                          type: 'relations',
                                        },
                                      ],
                                      false,
                                      keepAllWidgetsData,
                                      event.point.name,
                                    ));
                                  } else if (event.point.options.iteration) {
                                    let additionnalFetchParams = {
                                      refreshForm: true,
                                      clearSelection: true,
                                      clearResults: true,
                                    };
                                    /* On assigne l'event à une variable, car apres le dispatch(setDashboardLoading())
                                    l'event n'est plus accessible, INCOMPREHENSIBLE ! */
                                    const pointName = event.point.name;
                                    if (event.ctrlKey || event.metaKey) {
                                      additionnalFetchParams = {
                                        quickResultsScope: true,
                                      };
                                    } else {
                                      dispatch(setDashboardLoading());
                                    }
                                    dispatch(fetchResults({
                                      bodyItems: {
                                        premier: 1,
                                        dernier: tranche,
                                        base: activeBaseId,
                                        champs: {
                                          [`F_${idField}`]: `"${_.trim(pointName, ' "')}"`,
                                        },
                                      },
                                      ...additionnalFetchParams,
                                    }));
                                  } else {
                                    dispatch(refineAddFacetsValues([
                                      {
                                        champ: idField,
                                        strategie: event.point.name,
                                      },
                                    ], (event.ctrlKey || event.metaKey)));
                                  }
                                },
                                contextmenu: (event) => {
                                  const idField = getFieldIdFromFormFields(
                                    fields,
                                    `QES_${event.point.options.group}`,
                                  );

                                  let explorationDatas = {};
                                  if (widgetParams.withExploration) {
                                    explorationDatas = {
                                      widgetId: widgetParams.dashboard_widget,
                                      name: event.point.name,
                                      additionalQuery: buildNodesTypesAdditionalQuery(),
                                      joinedTypesValue: relationsLinksFields.map((field) => field.value).join(','),
                                      champs: { [`F_${idField}`]: `"${_.trim(event.point.name, ' "')}"` },
                                    };
                                  }

                                  dispatch(setContextMenu({
                                    hasExploration: widgetParams.withExploration,
                                    x: event.x,
                                    y: event.y,
                                    filterTitle: event.point.name,
                                    facetsValues: [{
                                      champ: idField,
                                      strategie: event.point.name,
                                    }],
                                    pointOptions: event.point.options,
                                    ...explorationDatas,
                                  }));
                                },
                              },
                            },
                          },
                        },
                        series: [{
                          nodes: _.get(series, '[0].nodes', []).map(
                            (node) => ({
                              ...node,
                              color: _.find(relationsNodesFields, { value: node.group })?.color,
                            }),
                          ),
                          data: _.get(series, '[0].data', []).map(
                            (link) => ({
                              ...link,
                              color: getLinkColor(link),
                            }),
                          ),
                        }],
                        title: {
                          text: computeTitle(widgetParams.title, series),
                          align: canEditDashboard ? 'center' : 'left',
                          margin: 20,
                        },
                      },
                    }}
                  />
                );
              }}
            </WidgetContainer>
          </div>
        );
      case 'wordcloud':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => (
                <DashboardWidget
                  canEditDashboard={canEditDashboard}
                  component={Wordcloud}
                  widgetParams={widgetParams}
                  handleExportWidget={() => handleExportWidget(widgetParams.dashboard_widget)}
                  highchartsOptions={{
                    options: {
                      tooltip: {
                        formatter: function tooltipFormatter() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${this.key}: <b>${this.point.weight}</b>`;
                        },
                      },
                      plotOptions: {
                        wordcloud: {
                          point: {
                            events: {
                              click: (event) => {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: event.point.options.name,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                              contextmenu: (event) => {
                                dispatch(setContextMenu({
                                  x: event.x,
                                  y: event.y,
                                  filterTitle: event.point.options.name,
                                  facetsValues: [{
                                    champ: getFieldIdFromFormFields(fields, additionalData.facets),
                                    strategie: event.point.options.name,
                                  }],
                                }));
                              },
                            },
                          },
                        },
                      },
                      series: [{
                        data: _.map(_.get(series, '[0].data', []), ([word, weight]) => ({
                          name: word,
                          weight,
                          color: getColorForFrequency(
                            theme.palette.mode === 'dark' ? 0.55 : 0.35,
                            series[0].minFreq,
                            series[0].maxFreq,
                            weight,
                            0,
                            theme.palette.mode === 'dark' ? '#ffffff' : null,
                          ),
                        })),
                        name: widgetParams.title,
                      }],
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: canEditDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </div>
        );
      case 'datatable':
        return (
          <div
            key={widgetParams.dashboard_widget}
            data-grid={{ ...dataGridParams, maxH: 2, minW: 2 }}
          >
            <WidgetContainer widgetId={widgetParams.dashboard_widget}>
              {({ series, additionalData }) => {
                const firstColumn = { name: '' };
                const additionnalOptions = {};
                if (widgetParams.computeLink) {
                  let computeLinkMethod;
                  if (widgetParams.computeLink === 'goToMitre') {
                    computeLinkMethod = goToMitre;
                  } else if (widgetParams.computeLink === 'goToAlienVault') {
                    computeLinkMethod = goToAlienVault;
                  }
                  firstColumn.cellRenderer = generateDatatableFirstCell(
                    dispatch,
                    fields,
                    additionalData.facets,
                    computeLinkMethod,
                  );
                } else {
                  additionnalOptions.onRowClick = (event, rowData) => dispatch(refineAddFacetsValues([
                    {
                      champ: getFieldIdFromFormFields(fields, additionalData.facets),
                      strategie: rowData[0],
                    },
                  ], (event.ctrlKey || event.metaKey)));
                  additionnalOptions.onRowContextMenu = (event, rowData) => {
                    event.preventDefault();
                    dispatch(setContextMenu({
                      x: event.pageX,
                      y: event.pageY,
                      filterTitle: rowData[0],
                      facetsValues: [{
                        champ: getFieldIdFromFormFields(fields, additionalData.facets),
                        strategie: rowData[0],
                      }],
                    }));
                  };
                }
                return (
                  <DashboardWidget
                    canEditDashboard={canEditDashboard}
                    component={Datatable}
                    componentProps={{
                      title: {
                        text: computeTitle(widgetParams.title, series),
                        align: canEditDashboard ? 'center' : 'left',
                        margin: 20,
                      },
                      columns: [
                        firstColumn,
                        { name: t('dashboard.widget.count'), align: 'right' },
                      ],
                      ...additionnalOptions,
                      data: _.get(series, '[0].data', []),
                    }}
                    widgetParams={widgetParams}
                    handleExportWidget={() => handleExportWidget(widgetParams.dashboard_widget)}
                  />
                );
              }}
            </WidgetContainer>
          </div>
        );
      default:
        return () => _.noop;
    }
  };

  if (loadingDashboards) {
    return <GlobalLoading />;
  }

  if (_.isEmpty(currentDashboard)) {
    return (
      <Button
        onClick={() => dispatch(openDialogEditOrCreateDashboard())}
        sx={{ mx: 'auto', mt: '100px' }}
      >
        {t('dashboard.add_new_dashboard')}
      </Button>
    );
  }

  return (
    <Fragment>
      <Box sx={{ paddingBottom: '40px', overflow: 'auto', height: '100%' }} ref={scrollContainer}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!_.isEmpty(currentDashboards) && currentDashboards.length > 1 && (
            <Tabs
              value={currentDashboard.dashboard}
              onChange={(__, tabId) => {
                push(`/search/dashboard/${activeBaseId}/${tabId}${location?.search || ''}`);
              }}
              sx={{ mx: 1, mb: 2 }}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {_.map(
                currentDashboards,
                (dashboard) => (
                  <Tab
                    sx={{ p: 1, pb: 0, textTransform: 'none' }}
                    label={dashboard.libelle}
                    key={dashboard.dashboard}
                    value={dashboard.dashboard}
                    icon={
                      !user.admin && (user.utilisateur !== dashboard.utilisateur) ? (
                        <LockOutlined fontSize="small" sx={{ width: '0.8em', marginLeft: '4px;' }} />
                      ) : ''
                    }
                    iconPosition="end"
                  />
                ),
              )}
            </Tabs>
          )}
        </Box>
        <CreateWidgetDialogContainer
          maxY={_.last(widgets)?.pos_y || 0}
          canCreateCooccurrencesWidget={
            !_.isEmpty(COOCCURRENCES_FIELDS)
            && !_.some(widgets, { type: 'cooccurrences' })
          }
          canCreateRelationsWidget={
            !_.isEmpty(RELATIONS_NODES_FIELDS)
            && !_.isEmpty(RELATIONS_LINKS_FIELDS)
            && !_.some(widgets, { type: 'relations' })
          }
          activeDashboardId={currentDashboard.dashboard}
        />
        <ResponsiveGridLayout
          className="layout"
          style={{ overflow: 'hidden', overflowX: 'hidden' }}
          isDraggable={canEditDashboard}
          isResizable={canEditDashboard}
          breakpoints={{ lg: 1200, md: 900 }}
          cols={{ lg: 6, md: 2 }}
          useCSSTransforms={false}
          onDragStop={handleSaveLayout}
          onResizeStop={handleSaveLayout}
          rowHeight={400}
          draggableHandle=".drag-handle"
        >
          {_.map(
            widgets,
            (widgetParams) => (
              renderWidget(widgetParams)
            ),
          )}
        </ResponsiveGridLayout>
      </Box>
      <DashboardFooter
        handleOpenDeleteDashboardDialog={handleOpenDeleteDashboardDialog}
        handleOpenCreateDashboardDialog={() => dispatch(openDialogEditOrCreateDashboard())}
        handleOpenEditDashboardDialog={() => dispatch(openDialogEditOrCreateDashboard(currentDashboard))}
        handleOpenCreateWidgetDialog={() => dispatch(openDialogCreateWidget())}
        canEditDashboard={canEditDashboard}
        canCreateDashboard={DYNAMIC_DASHBOARD}
      />
      <ContextMenu ref={scrollContainer} relationsLinksFilterValue={relationsLinksFilterValue} />
    </Fragment>
  );
};

export default React.memo(DashboardChartsContainer);
